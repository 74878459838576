<template>
  <div
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 100px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="monevoPopupHeader">
      <label style="font-size: 15px">Equifax&nbsp;</label>
      <label style="font-size: 15px">{{ getActivePanelLabel() }}</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <div class="col-md-12 row">
      <perfect-scrollbar
        style="
          width: 100%;
          height: auto;
          max-height: 480px;
          overflow-x: hidden !important;
          margin-top: 0;
        "
      >
        <div class="col-md-12" v-if="panels[0].IsActive">
          <label class="generalLabel mt-2">DIT Logs:</label>
          <section class="datagrid-table" style="border-radius: 0 !important">
            <o-table
              :data="ditLogs"
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              :striped="isStriped"
              :hoverable="isHoverable"
            >
              <o-table-column
                field="RequestedBy"
                label="Request By"
                width="40%"
                sortable
                v-slot="props"
                >{{ props.row.RequestedBy }}</o-table-column
              >
              <o-table-column
                field="AuthDate"
                label="Log Date"
                width="30%"
                sortable
                v-slot="props"
                >{{ formatGridDate(props.row.AuthDate) }}</o-table-column
              >
              <o-table-column
                field="Status"
                label="Status"
                width="30%"
                v-slot="props"
                sortable
              >
                {{ props.row.Status }}
                <i
                  v-if="
                    props.row.Status &&
                    props.row.Status.toLowerCase() == 'pending'
                  "
                  @click="showQuestions(props.row)"
                  title="Show questions"
                  class="fas fa-question-circle mt-1 ml-2"
                  style="color: #3bafda; cursor: pointer; font-size: 20px"
                ></i>
              </o-table-column>
            </o-table>
            <section
              v-if="ditLogs.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </section>
          <div class="col-md-12 text-center">
            <button
              @click="changePanel(2)"
              type="button"
              class="btn btn-success mt-2"
            >
              New DIT Request
            </button>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[1].IsActive">
          <div class="row" v-if="customerInfo != null">
            <div class="col-md-4">
              <label class="generalLabel">First Name</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="First Name"
                v-model="customerInfo.FirstName"
                :class="v$.customerInfo.FirstName.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.FirstName.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Middle Name</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Middle Initial"
                v-model="customerInfo.MiddleName"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Last Name</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Last Name"
                v-model="customerInfo.LastName"
                :class="v$.customerInfo.LastName.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.LastName.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Date of Birth</label>
              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="customerInfo.DateOfBirth"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Date of Birth"
                    :class="
                      v$.customerInfo.DateOfBirth.$error ? 'has-error' : ''
                    "
                    @blur="v$.customerInfo.DateOfBirth.$touch"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-md-4">
              <label class="generalLabel">SSN</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="SSN"
                v-myMask="{ mask: ['999-99-9999'] }"
                v-model="customerInfo.Ssn"
                :class="v$.customerInfo.Ssn.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.Ssn.$touch"
              />
            </div>
            
            <div class="col-md-4">
              <label class="generalLabel">Address Line1</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Line1"
                v-model="customerInfo.AddressLine1"
                :class="v$.customerInfo.AddressLine1.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.AddressLine1.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Address Line2</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Line2"
                v-model="customerInfo.AddressLine2"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Zip Code</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                v-myMask="{ mask: ['99999'] }"
                placeholder="Zip Code"
                v-model="customerInfo.Zip"
                :class="v$.customerInfo.Zip.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.Zip.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">City</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="City"
                v-model="customerInfo.City"
                :class="v$.customerInfo.City.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.City.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">State</label>
              <select
                        v-model="customerInfo.StateId"
                        id="State"
                        class="generalSelectBox form-control"
                   
                      >
                        <option
                          v-for="item in states"
                          :key="item.Id"
                          :value="item.Id"
                        >
                          {{ item.Name }}
                        </option>
                      </select>
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Reference Id</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="First Name"
                v-model="tempReferenceId"
              />
            </div>
            <div class="col-md-12 text-center">
              <button
                @click="sendDitRequest()"
                :disabled="v$.$invalid"
                type="button"
                class="btn btn-success mt-2"
              >
                Send DIT Request
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[2].IsActive">
          <div class="row" v-if="questions != null && questions.length > 0">
            <div
              class="col-md-12"
              v-for="item in questions"
              :key="item.questionId"
            >
              <label class="generalLabel w-100">{{ item.questionText }}</label>
              <div
                v-for="c in item.choices"
                :key="c.choiceId"
                class="col-md-12"
              >
                <div class="d-flex align-items-center">
                  <input
                    class="mr-1"
                    type="radio"
                    :value="c.choiceId"
                    :name="'answer-' + item.questionId"
                    v-model="item.answers"
                  />
                  <label class="noMargin">{{ c.choiceText }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                :disabled="!isValidationRequestEnabled()"
                @click="sendValidationRequest"
                type="button"
                class="btn btn-success mt-2"
              >
                Validate
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[3].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel">Digital identity verification failed.</label>
              <button
                @click="requestMFA"
                type="button"
                class="btn btn-success mt-2"
              >
                Request MFA
              </button>
              <button
             @click="changePanel(2)"
                type="button"
                class="btn btn-success mt-2 ml-2"
              >
                New DIT Request
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[4].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel">Digital identity verified successfully.</label>
              <button
                @click="requestOneview"
                type="button"
                class="btn btn-success mt-2"
              >
                Request One View
              </button>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { DatePicker } from "v-calendar";
import deepCopy from "@/helpers/deepCopy";
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types";
export default {
  name: "EquifaxPopup",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],

  props: {
    data: {
      Type: Object,
      Defult: null,
    },
  },
  computed: mapState({
    states: (state) => state.globals.states,
  }),
  data() {
    return {
      isLoading: false,
      v$: useValidate(),
      customerInfo: null,
      tempReferenceId: "test-0053",
      equifaxResponseObj: {
        CreditPullEquifaxAuthId: 0,
        Status: "",
        CorrelationId: 0,
        QuestionnaireId: 0,
        AuthenticationId: "",
      },
      panels: [
        { Id: 1, Name: "DitLogs", Label: "DIT Logs", IsActive: true },
        {
          Id: 2,
          Name: "DitRequest",
          Label: "New DIT Request",
          IsActive: false,
        },
        { Id: 3, Name: "Questions", Label: "Questions", IsActive: false },
        {
          Id: 4,
          Name: "MfaValidation",
          Label: "MFA Validation",
          IsActive: false,
        },
        { Id: 5, Name: "Oneview", Label: "Request Oneview", IsActive: false },
      ],
      ditLogs:[],
      questions: [],
    };
  },
  unmounted() {},
  async mounted() {
    if (this.data) {
      this.customerInfo = this.data;
      if(this.customerInfo.CustomerId > 0)
      {
        this.isLoading = true;
        try{
        await  this.$store.dispatch(globalTypes.GET_STATES);
        let err,result;
        [err, result] = await this.$store.dispatch(
        types.GET_EQUIFAX_DIT_LOGS,
        {CustomerId:this.customerInfo.CustomerId}
        );

        if(result && result.Data)
        {
          this.ditLogs = deepCopy(result.Data);
        }
        }
        catch{
           //
        }
        this.isLoading = false;
      }
    }
  },
  methods: {
    
    getStateShort(stateId) {
      let rtn="";
      if(this.states && this.states.length> 0)
      {
        let stateIndex = this.states.findIndex((x) => x.Id == stateId);
        rtn= stateIndex > -1 ? this.states[stateIndex].ShortCode : "";
      }
      return rtn;
   
    },
    isValidationRequestEnabled() {
      let rtn = false;

      if (this.questions && this.questions.length > 0) {
        let noneAnsweredList = this.questions.filter((x) => x.answers == "");
        if (noneAnsweredList.length == 0) {
          rtn = true;
        }
      }

      return rtn;
    },
    closePanel() {
      this.$emit("closeEquifaxPopup");
    },
    showQuestions(obj) {

      if(obj)
      {
        this.equifaxResponseObj.CreditPullEquifaxAuthId = obj.CreditPullEquifaxAuthId;
        this.equifaxResponseObj.CorrelationId =obj.CorrelationId;
        this.equifaxResponseObj.QuestionnaireId =obj.QuestionnaireId;
        this.equifaxResponseObj.AuthenticationId = obj.AuthenticationId;
        if(obj.Questions && obj.Questions.length > 0)
        {
          this.questions = deepCopy(obj.Questions);
          for (let item of this.questions) {
                item.answers = "";
              }
              this.changePanel(3);
        }
      }


    },
    changePanel(toId) {
      for (let item of this.panels) {
        item.IsActive = false;
      }
      let toIndex = this.panels.findIndex((x) => x.Id == toId);
      if (toIndex > -1) {
        this.panels[toIndex].IsActive = true;
      }
    },
    async sendDitRequest() {
      let dobFormatted = "";
      if (this.customerInfo.DateOfBirth) {
        dobFormatted = this.formatShortDate(this.customerInfo.DateOfBirth);
      }

      let payload = {
        CustomerId: this.customerInfo.CustomerId,
        ReferenceId: this.tempReferenceId != "" ? this.tempReferenceId : this.customerInfo.CustomerId+"-dit-initiate",
        Ssn: this.customerInfo.Ssn,
        FirstName: this.customerInfo.FirstName,
        LastName: this.customerInfo.LastName,
        MiddleName: this.customerInfo.MiddleName,
        AddressLine1: this.customerInfo.AddressLine1,
        AddressLine2: this.customerInfo.AddressLine2 ? this.customerInfo.AddressLine2 :"",
        City: this.customerInfo.City,
        State: this.getStateShort(this.customerInfo.StateId),
        PostalCode: this.customerInfo.Zip,
        Dob: dobFormatted,
      };

      this.isLoading=true;
      try{
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.SEND_EQUIFAX_DIT_REQUEST,
        payload
      );
      if (result) {
        if (result.Data) {
          let resultStatus = result.Data.Status
            ? result.Data.Status.toLowerCase()
            : "";
          if (resultStatus == "complete") {
            this.changePanel(5);
          } else if (resultStatus == "pending") {
            this.questions = deepCopy(result.Data.Questions);
            if (
              typeof this.questions != "undefined" &&
              this.questions &&
              this.questions.length > 0
            ) {
              for (let item of this.questions) {
                item.answers = "";
              }
            }
            this.equifaxResponseObj.CreditPullEquifaxAuthId =
              result.Data.CreditPullEquifaxAuthId;
            this.equifaxResponseObj.Status = result.Data.Status;
            this.equifaxResponseObj.CorrelationId = result.Data.CorrelationId;
            this.equifaxResponseObj.QuestionnaireId =
              result.Data.QuestionnaireId;
            this.equifaxResponseObj.AuthenticationId =
              result.Data.AuthenticationId;
            this.changePanel(3);
          } else if (resultStatus == "denied") {
            this.changePanel(4);
          }
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    }catch{
           //
        }
      this.isLoading=false;
    },
    async sendValidationRequest() {
      let questionAnswers = [];
      if (this.questions && this.questions.length > 0) {
        for (let item of this.questions) {
          questionAnswers.push({
            questionId: item.questionId,
            answers: [parseInt(item.answers)],
          });
        }
      }

      let payload = {
        ReferenceId: this.tempReferenceId != "" ? this.tempReferenceId :  this.customerInfo.CustomerId+"-dit-kba",
        CustomerId: this.customerInfo.CustomerId,
        CreditPullEquifaxAuthId:
          this.equifaxResponseObj.CreditPullEquifaxAuthId,
        CorrelationId: this.equifaxResponseObj.CorrelationId,
        QuestionnaireId: this.equifaxResponseObj.QuestionnaireId,
        AuthenticationId: this.equifaxResponseObj.AuthenticationId,
        QuestionAnswers: deepCopy(questionAnswers),
      };
      this.isLoading=true;
      try{
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.SEND_EQUIFAX_KBA_ANSWERS,
        payload
      );
      if (result) {
        let resultStatus = result.Data.Status
          ? result.Data.Status.toLowerCase()
          : "";
        if (resultStatus == "complete") {
          this.changePanel(5);
        } else if (resultStatus == "denied") {
          this.changePanel(4);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }}catch{
           //
        }
      this.isLoading=false;
    },
    requestMFA() {},
    getActivePanelLabel() {
      let rtn = "";
      if (this.panels && this.panels.length > 0) {
        let indx = this.panels.findIndex((x) => x.IsActive == true);
        if (indx > -1) {
          rtn = "- " + this.panels[indx].Label;
        }
      }
      return rtn;
    },
  },

  validations() {
    return {
      customerInfo: {
        FirstName: { required },
        LastName: { required },
        Ssn: { required },
        DateOfBirth: { required },
        City: { required },
        AddressLine1: { required },
        Zip: { required },
      },
    };
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.monevoPopupHeader {
  padding-left: 15px;
  margin-top: -10px;
}
.textLabel {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.monevoDisclaimerLabel {
  font-size: 0.72em;
  color: #999;
}
.monevoDisclaimerLabel p {
  margin: 5px 0;
  font-size: 0.72em;
  color: #999;
}
</style>
