<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
      max-height: 640px !important;
      overflow-x: hidden;
      overflow-y: auto;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div>
      <div>
        <div
          class="col-md-12 noPadding"
          style="position: relative; top: -10px; left: 10px"
        >
          <label for="Title" class="generalLabel">Payment Setup</label>
        </div>
        <div class="closeButtonDR" v-on:click.prevent.stop="closePanel">
          <i class="fas fa-window-close"></i>
        </div>
      </div>
      <div class="col-md-12" style="background-color: #3bafda">
        <label
          for="Title"
          class="generalLabel"
          style="display: flex; padding: 10px 0px 10px 0px; color: white"
          >Payment Type: {{ getPaymentTypeNames() }}</label
        >
      </div>
    </div>
    <div class="col-md-12 noPadding form-group row mt-1" v-if="task">
      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 panelWrapper noMargin"
      >
        <ul class="nav nav-tabs">
          <li v-for="tab in tabs" :key="tab.id" class="nav-item tab-item">
            <a
              v-if="tab.visible"
              data-toggle="tab"
              :aria-expanded="tab.active"
              v-on:click.prevent="() => handleTabChange(tab.name)"
              class="tab-link"
              :class="{ active: tab.active }"
            >
              <span class="d-sm-inline-block tab-item-label">
                {{ tab.label }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12">
      <div v-show="tabs[0].active" class="form-group row">
        <div class="col-md-12">
          <label for="selectPayee" class="generalLabel" style="font-size: 18px">
            Select or add payee:
            <span style="color: green">{{ selectedPayee.PayeeName }}</span>
          </label>
          <div class="col-md-12" style="border: 1px #ededed solid"></div>
        </div>
        <div class="col-md-12" style="display: flex; align-items: center">
          <div class="col-md-2">
            <label for="payeeName" class="generalLabel">Payee Name</label>
          </div>
          <div class="col-md-5">
            <input
              type="text"
              id="payeeNameInp"
              class="generalTextBox form-control"
              autocomplete="off"
              placeholder="Please type a payee name"
              v-model="payeeFilter"
              @keypress="payeeNameEnter"
            />
          </div>
          <div class="col-md-5">
            <button
              type="button"
              class="btn btn-outline-info waves-effect waves-light btn-sm"
              style="border-radius: 5px; padding: 8px 12px"
              @click="searchPayee()"
              :disabled="payeeFilter.trim().length == 0"
            >
              <i class="fas fa-search"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light ml-2"
              style="border-radius: 5px"
              @click="showNewPayeePanel()"
            >
              Add New
            </button>
          </div>
        </div>
        <div class="col-md-12" v-if="!isNewPayeeAdding">
          <div class="offset-2 col-md-5">
            <perfect-scrollbar
              v-if="payeeList.length > 0"
              style="padding: 10px; height: 120px; background-color: #efefef"
            >
              <p
                @click="selectPayee(item)"
                v-for="item in payeeList"
                :key="item.PayeeId"
                style="cursor: pointer"
              >
                {{ item.PayeeName }}
              </p>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="col-md-12 row" v-if="isNewPayeeAdding">
          <div class="col-md-6">
            <label class="generalLabel">Payee</label>
            <input
              type="text"
              id="payeeInp"
              class="generalTextBox form-control"
              placeholder="Please type a payee"
              v-model="newPayee.PayeeName"
            />
          </div>
          <div class="col-md-6">
            <label class="generalLabel">DRC Payee ID</label>
            <input
              type="text"
              id="payeeInp"
              class="generalTextBox form-control"
              placeholder="Please type a DRC Payee ID"
              v-model="newPayee.PayeeId"
            />
          </div>
          <div
            class="col-md-12"
            style="display: flex; justify-content: flex-end"
          >
            <button
              :disabled="
                newPayee.PayeeId.trim().length == 0 ||
                newPayee.PayeeName.trim().length == 0
              "
              @click="addNewPayee()"
              type="button"
              class="btn btn-success waves-effect waves-light col-md-2"
            >
              Confirm
            </button>
            <button
              @click="cancelNewPayee()"
              type="button"
              class="btn btn-danger waves-effect waves-light ml-2 col-md-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div v-show="tabs[1].active" class="form-group row">
        <div v-if="!bankTabActive" class="col-md-12">
          <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
            You must fill previous tab data to use this section.
          </div>
        </div>
        <div v-if="bankTabActive && selectedBankId > 0" class="col-md-12">
          <div class="text-white bg-success pt-1 pl-2 pr-2 pb-1">
            Bank Id: {{ selectedBankId }} selected.
          </div>
        </div>

        <div v-if="bankTabActive && selectedBankId == 0" class="sub-info ml-3">
          Double click to select on grid
        </div>

        <div class="col-md-12" v-if="bankTabActive">
          <button
            v-if="payeeDetails && payeeDetails.Banks && !task.IsDpg"
            @click="addBank()"
            class="btn btn-sm generalButton"
            style="
              background-color: #1abc9c;
              color: #ffffff;
              position: absolute;
              top: 12px;
              right: 20px;
              z-index: 10;
            "
          >
            Add new
          </button>
          <perfect-scrollbar
            v-if="payeeDetails && payeeDetails.Banks"
            class="col-md-12 datagrid-table"
            style="max-height: 180px; cursor: pointer"
          >
            <o-table
              :data="isEmpty ? [] : payeeDetails.Banks"
              :bordered="isBordered"
              :striped="isStriped"
              :hoverable="isHoverable"
              :focusable="isFocusable"
              @dblclick="selectBank($event)"
            >
              <o-table-column
                field="RoutingNumber"
                label="Routing Number"
                sortable
                v-slot="props"
              >
                {{ props.row.RoutingNumber }}
              </o-table-column>

              <o-table-column
                field="AccountNumber"
                label="Account Number"
                sortable
                v-slot="props"
              >
                {{ props.row.AccountNumber }}
              </o-table-column>

              <o-table-column
                field="AccountType"
                label="Account Type"
                sortable
                position="centered"
                v-slot="props"
              >
                <span style="text-align: center">
                  {{ props.row.AccountType }}
                </span>
              </o-table-column>

              <o-table-column field="edit" v-slot="props">
                <i
                  @click="editBank(props.row)"
                  class="fas fa-pen"
                  style="
                    color: rgb(29, 161, 242);
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                ></i>
              </o-table-column>
              <o-table-column field="delete" v-slot="props" v-if="!task.IsDpg">
                <i
                  @click="deleteBank(props.row.BankId)"
                  class="fas fa-times"
                  style="
                    color: red;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                ></i>
              </o-table-column>
            </o-table>
          </perfect-scrollbar>
          <div
            v-if="bankEditMenuOpen"
            class="col-md-12"
            style="background-color: #efefef; padding: 5px 0"
          >
            <div class="row">
              
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="bankRoutingNumber"
                    >Routing Number</label
                  >
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="bankRoutingNumber"
                    class="generalTextBox"
                    placeholder="Routing Number"
                    v-model="selectedBank.RoutingNumber"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="bankAccountNumber"
                    >Account Number</label
                  >
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="bankAccountNumber"
                    class="generalTextBox"
                    placeholder="Account Number"
                    v-model="selectedBank.AccountNumber"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="bankRoutingNumber"
                    >Account Type</label
                  >
                </div>
                <div class="col-md-12 noMargin">
                  <select
                    v-model="selectedBank.AccountType"
                    id="slcAccountType"
                    class="generalSelectBox form-control"
                  >
                    <option :value="getAccountTypeKey(true)">Checking</option>
                    <option :value="getAccountTypeKey(false)">Savings</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="bankRoutingNumber"></label>
                </div>
                <div class="col-md-12 noMargin">
                  <button
                    @click="saveBank()"
                    :disabled="
                      selectedBank.AccountNumber.trim().length == '' ||
                      selectedBank.RoutingNumber.trim().length == ''
                    "
                    class="btn btn-sm generalButton"
                    style="
                      background-color: #3bafda;
                      color: #ffffff;
                      margin-top: 2px;
                    "
                  >
                    {{ selectedBank.BankId == 0 ? "Add" : "Save" }}
                  </button>
                  <button
                    @click="cancelBank()"
                    class="btn btn-sm generalButton"
                    style="
                      background-color: #ed3030;
                      color: #ffffff;
                      margin-top: 2px;
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tabs[2].active" class="form-group row">
        <div v-if="!contactTabActive" class="col-md-12">
          <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
            You must fill previous tab data to use this section.
          </div>
        </div>
        <div v-if="contactTabActive && selectedContactId > 0" class="col-md-12">
          <div class="text-white bg-success pt-1 pl-2 pr-2 pb-1">
            Contact Id: {{ selectedContactId }} selected.
          </div>

          <div
            v-if="contactTabActive && selectedContactId == 0"
            class="sub-info ml-3"
          >
            Double click to select on grid
          </div>
        </div>
        <div v-if="contactTabActive" class="col-md-12">
          <button
            v-if="payeeDetails && payeeDetails.Contacts"
            @click="addContact()"
            type="button"
            class="btn btn-sm generalButton"
            style="
              background-color: #1abc9c;
              color: #ffffff;
              position: absolute;
              top: 12px;
              right: 20px;
              z-index: 10;
            "
          >
            Add new
          </button>
          <perfect-scrollbar
            class="col-md-12 datagrid-table"
            style="max-height: 180px; cursor: pointer"
            v-if="payeeDetails && payeeDetails.Contacts"
          >
            <o-table
              :data="isEmpty ? [] : payeeDetails.Contacts"
              :bordered="isBordered"
              :striped="isStriped"
              :hoverable="isHoverable"
              :focusable="isFocusable"
              @dblclick="selectContact($event)"
            >
              <o-table-column field="Name" label="Name" sortable v-slot="props">
                {{ props.row.Name }}
              </o-table-column>

              <o-table-column
                field="Phone"
                label="Phone Number"
                sortable
                v-slot="props"
              >
                {{ tryFormatPhone(props.row.Phone) }}
              </o-table-column>

              <o-table-column
                field="Email"
                label="Email Address"
                sortable
                v-slot="props"
              >
                {{ props.row.Email }}
              </o-table-column>

              <o-table-column field="edit" v-slot="props">
                <i
                  @click="editContact(props.row)"
                  class="fas fa-pen"
                  style="
                    color: rgb(29, 161, 242);
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                ></i>
              </o-table-column>
              <o-table-column field="delete" v-slot="props" v-if="task.PaymentProcessorId ==2 || task.PaymentProcessorId ==3 ">
                <i
                  @click="deleteContact(props.row.ContactId)"
                  class="fas fa-times"
                  style="
                    color: red;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                ></i>
              </o-table-column>
            </o-table>
          </perfect-scrollbar>
          <div
            v-if="contactEditMenuOpen"
            class="col-md-12"
            style="background-color: #efefef; padding: 5px 0"
          >
            <div class="row">
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="contactName">Name</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="contactName"
                    class="generalTextBox"
                    placeholder="Name"
                    v-model="selectedContact.Name"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="contactPhone">Phone</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="contactPhone"
                    class="generalTextBox"
                    placeholder="Phone"
                    v-myMask="{ mask: ['(999) 999-9999'] }"
                    v-model="selectedContact.Phone"
                    @change="phoneChanged"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="contactEmail">Email</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="contactEmail"
                    class="generalTextBox"
                    placeholder="Email"
                    v-model="selectedContact.Email"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="bankRoutingNumber"></label>
                </div>
                <div class="col-md-12 noMargin">
                  <button
                    v-if="task.PaymentProcessorId ==2 || task.PaymentProcessorId ==3 "
                    @click="saveContact()"
                    :disabled="
                      selectedContact.Phone.trim().length == '' ||
                      selectedContact.Email.trim().length == ''
                    "
                    class="btn btn-sm generalButton"
                    style="
                      background-color: #3bafda;
                      color: #ffffff;
                      margin-top: 2px;
                    "
                  >
                    {{ selectedContact.ContactId == 0 ? "Add" : "Save" }}
                  </button>
                  <button
                    @click="cancelContact()"
                    class="btn btn-sm generalButton"
                    style="
                      background-color: #ed3030;
                      color: #ffffff;
                      margin-top: 2px;
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tabs[3].active" class="form-group row">
        <div v-if="!addressTabActive" class="col-md-12">
          <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
            You must fill previous tab data to use this section.
          </div>
        </div>
        <div v-if="addressTabActive && selectedAddressId > 0" class="col-md-12">
          <div class="text-white bg-success pt-1 pl-2 pr-2 pb-1">
            Address Id: {{ selectedAddressId }}
          </div>
        </div>

        <div
          v-if="addressTabActive && selectedAddressId == 0"
          class="sub-info ml-3"
        >
          Double click to select on grid
        </div>
        <div v-if="addressTabActive" class="col-md-12">
          <button
            v-if="payeeDetails && payeeDetails.Addresses"
            @click="addAddress()"
            type="button"
            class="btn btn-xs generalButton"
            style="
              background-color: #1abc9c;
              color: #ffffff;
              position: absolute;
              top: -34px;
              right: 12px;
              z-index: 10;
            "
          >
            Add new
          </button>

          <perfect-scrollbar
            v-if="payeeDetails && payeeDetails.Addresses"
            class="col-md-12 datagrid-table"
            style="max-height: 180px; cursor: pointer"
          >
            <o-table
              :data="isEmpty ? [] : payeeDetails.Addresses"
              :bordered="isBordered"
              :striped="isStriped"
              :hoverable="isHoverable"
              :focusable="isFocusable"
              @dblclick="selectAddress($event)"
            >
              <o-table-column
                field="Address1"
                label="Address 1"
                sortable
                v-slot="props"
              >
                {{ props.row.Address1 }}
              </o-table-column>

              <o-table-column
                field="Address2"
                label="Address 2"
                sortable
                v-slot="props"
              >
                {{ props.row.Address2 }}
              </o-table-column>

              <o-table-column
                field="City"
                label="City/Municipality"
                sortable
                v-slot="props"
              >
                {{ props.row.City }}
              </o-table-column>

              <o-table-column field="State" label="State" v-slot="props">
                {{ props.row.State }}
              </o-table-column>
              <o-table-column field="Zip" label="Zip Code" v-slot="props">
                {{ props.row.Zip }}
              </o-table-column>
              <o-table-column
                field="Email"
                label="Email"
                v-slot="props"
                v-if="task.PaymentProcessorId ==2"
              >
                {{ props.row.Email }}
              </o-table-column>
              <o-table-column
                field="Phone"
                label="Phone"
                v-slot="props"
                v-if="task.PaymentProcessorId ==2"
              >
                {{ props.row.Phone }}
              </o-table-column>
              <o-table-column field="edit" v-slot="props" v-if="!task.IsDpg">
                <i
                  @click="editAddress(props.row)"
                  class="fas fa-pen"
                  style="
                    color: rgb(29, 161, 242);
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                ></i>
              </o-table-column>
              <o-table-column field="delete" v-slot="props" v-if="!task.IsDpg">
                <i
                  @click="deleteAddress(props.row.AddressId)"
                  class="fas fa-times"
                  style="
                    color: red;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                ></i>
              </o-table-column>
            </o-table>
          </perfect-scrollbar>
          <div
            v-if="addressEditMenuOpen"
            class="col-md-12"
            style="background-color: #efefef; padding: 5px 0"
          >
            <div class="row">
              <div class="col-md-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="address1">Address 1</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="address1"
                    class="generalTextBox"
                    placeholder="Address 1"
                    style="width: 100%"
                    v-model="selectedAddress.Address1"
                    v-on:input="checkLength"
                  />
                  <p
                    class="noPadding noMargin"
                    style="color: #ff7979"
                    v-if="isMaxLimitReachedAdress"
                  >
                    Maximum 50 characters are allowed!
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="address2">Address 2</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="address2"
                    class="generalTextBox"
                    placeholder="Address 2"
                    style="width: 100%"
                    v-model="selectedAddress.Address2"
                    v-on:input="checkLength"
                  />
                  <p
                    class="noPadding noMargin"
                    style="color: #ff7979"
                    v-if="isMaxLimitReachedAdress2"
                  >
                    Maximum 50 characters are allowed!
                  </p>
                </div>
              </div>
              <div class="col-md-4" v-if="!task.IsDpg">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="addressCity">City</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="addressCity"
                    class="generalTextBox"
                    placeholder="City"
                    style="width: 100%"
                    v-model="selectedAddress.City"
                    v-on:input="checkLength"
                  />
                  <p
                    class="noPadding noMargin"
                    style="color: #ff7979"
                    v-if="isMaxLimitReachedCity"
                  >
                    Maximum 50 characters are allowed!
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="contactEmail">State</label>
                </div>
                <div class="col-md-12 noMargin">
                  <select
                    v-model="selectedAddress.State"
                    id="State"
                    class="generalSelectBox form-control"
                  >
                    <option value="">Select</option>
                    <option
                      v-for="item in states"
                      :key="item.Id"
                      :value="item.ShortCode"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="addressZip">Zip</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="addressZip"
                    class="generalTextBox"
                    placeholder="Zip"
                    v-myMask="{ mask: ['99999'] }"
                    v-model="selectedAddress.Zip"
                    @change="zipChanged"
                  />
                </div>
              </div>
              <div class="col-md-4" v-if="task.PaymentProcessorId ==2">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="contactPhone">Phone</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="contactPhone"
                    class="generalTextBox"
                    placeholder="Phone"
                    v-myMask="{ mask: ['(999) 999-9999'] }"
                    v-model="selectedAddress.Phone"
                    @change="phoneChangedDpg"
                  />
                </div>
              </div>
              <div class="col-md-4" v-if="task.PaymentProcessorId ==2">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel" for="contactEmail">Email</label>
                </div>
                <div class="col-md-12 noMargin">
                  <input
                    type="text"
                    id="contactEmail"
                    style="width: 100%"
                    class="generalTextBox"
                    placeholder="Email"
                    v-model="selectedAddress.Email"
                  />
                </div>
              </div>
              <div class="col-md-12 text-right noMargin">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel"></label>
                </div>
                <div class="col-md-12 noMargin">
                  <button
                    @click="saveAddress()"
                    class="btn btn-sm generalButton"
                    style="
                      background-color: #3bafda;
                      color: #ffffff;
                      margin-top: 2px;
                    "
                  >
                    {{ selectedAddress.AddressId == 0 ? "Add" : "Save" }}
                  </button>
                  <button
                    @click="cancelAddress()"
                    class="btn btn-sm generalButton"
                    style="
                      background-color: #ed3030;
                      color: #ffffff;
                      margin-top: 2px;
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tabs[4].active" class="form-group row">
        <div v-if="!paymentInfoTabActive" class="col-md-12">
          <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
            You must fill previous tab data to use this section.
          </div>
        </div>
        <div v-if="paymentInfoTabActive" class="col-md-12 row">
          <div class="col-md-4">
            <div class="mb-2">
              <label for="accountNumber" class="generalLabel"
                >Account Number</label
              >
              <input
                type="text"
                id="accountNumberInp"
                class="generalTextBox form-control"
                placeholder="Please type a account number"
                v-model="paymentInfo.AccountNumber"
                @blur="
                  removeLeadingSpaces();
                  v$.paymentInfo.AccountNumber.$touch();
                "
                v-on:input="checkLengthAccountNumber"
                :class="v$.paymentInfo.AccountNumber.$error ? 'has-error' : ''"
              />
              <p style="color: #ff7979" v-if="isCheckLengthAccountNumber">
                Maximum 40 characters are allowed!
              </p>
            </div>
            <div class="mb-2">
              <label for="accountNumber" class="generalLabel"
                >Global Memo 1</label
              >
              <input
                type="text"
                id="accountNumberInp"
                class="generalTextBox form-control"
                placeholder="Please type a global memo 1"
                v-model="paymentInfo.GlobalMemo.Memo1"
                @blur="removeLeadingSpaces"
                v-on:input="checkLengthMemo1"
              />
              <p style="color: #ff7979" v-if="isCheckMemo1">
                Maximum 40 characters are allowed!
              </p>
            </div>
            <div class="mb-2">
              <label for="accountNumber" class="generalLabel"
                >Global Memo 2</label
              >
              <input
                type="text"
                id="accountNumberInp2"
                class="generalTextBox form-control"
                placeholder="Please type a global memo 2"
                v-model="paymentInfo.GlobalMemo.Memo2"
                @blur="removeLeadingSpaces"
                v-on:input="checkLengthMemo2"
              />
              <p style="color: #ff7979" v-if="isCheckMemo2">
                Maximum 40 characters are allowed!
              </p>
            </div>
            <div class="mb-2">
              <label for="accountNumber" class="generalLabel"
                >Global Memo 3</label
              >
              <input
                type="text"
                id="accountNumberInp3"
                class="generalTextBox form-control"
                placeholder="Please type a global memo 3"
                v-model="paymentInfo.GlobalMemo.Memo3"
                @blur="removeLeadingSpaces"
                v-on:input="checkLengthMemo3"
              />
              <p style="color: #ff7979" v-if="isCheckMemo3">
                Maximum 40 characters are allowed!
              </p>
            </div>
          </div>
          <div class="col-md-4" v-if="task.PaymentDetails.length == 0">
            <div>
              <label for="settlementOffer" class="generalLabel"
                >Settlement Offer</label
              >
              <label class="generalLabel ml-2">
                {{ formatMoney(task.Offer) }}
              </label>
            </div>

            <div>
              <label for="settlementOffer" class="generalLabel"
                >Settlement Fee</label
              >
              <label class="generalLabel ml-2">
                {{ formatMoney(task.SettlementFee) }}
              </label>
            </div>

            <div>
              <label for="paymentDate" class="generalLabel">Payment Date</label>
              <label for="settlementOffer" class="generalLabel ml-2">
                {{ formatShortDate(task.PaymentDay) }}
              </label>
            </div>
          </div>
          <div class="col-md-6" v-if="task.PaymentDetails.length > 0">
            <label for="accountNumber" class="generalLabel"
              >Payment Details:</label
            >
            <div class="row" style="width: 90%; font-weight: bold">
              <div class="col-md-4">Date</div>
              <div class="col-md-4">Amount</div>
              <div class="col-md-4">Fee</div>
            </div>
            <perfect-scrollbar style="max-width: 100%; max-height: 230px">
              <div
                class="row"
                style="width: 98%"
                v-for="item in task.PaymentDetails"
                :key="item.Id"
              >
                <div class="col-md-4">
                  {{ formatShortDate(item.PaymentDate) }}
                </div>
                <div class="col-md-4">
                  {{ formatMoney(item.PaymentAmount) }}
                </div>
                <div class="col-md-4">{{ formatMoney(item.FeeAmount) }}</div>
              </div>
            </perfect-scrollbar>
          </div>
          <div
            class="col-12 noPadding noMargin"
            style="display: flex; justify-content: flex-end"
          >
            <button
              :disabled="selectedPayee == null || v$.$invalid"
              @click="savePaymentInfo()"
              type="button"
              class="btn btn-success waves-effect waves-light col-2"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
const PAYMENT_TYPES = {
  ACH: 4,
  ManualCheck: 5,
  CheckByPhone: 6,
  SecondDayCheck: 7,
  OverniteCheck: 8,
  WireTransfer: 9,
  GlobalDirctPay: 10,
};
export default {
  name: "PaymentSetupPopup",
  components: {},
  props: {
    task: {
      type: Object,
      default: null,
    },
    paymentTypeList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      v$: useValidate(),
      isMaxLimitReachedAdress: false,
      isMaxLimitReachedAdress2: false,
      isMaxLimitReachedCity: false,
      isCheckLengthAccountNumber: false,
      isCheckMemo1: false,
      isCheckMemo2: false,
      isCheckMemo3: false,

      isLoading: false,
      tabs: [
        {
          id: 1,
          name: "payeeInfo",
          label: "Payee Info",
          iconName: "",
          active: true,
          visible: true,
        },
        {
          id: 2,
          name: "payeeBank",
          label: "Payee Bank",
          iconName: "",
          active: false,
          visible: false,
        },
        {
          id: 3,
          name: "payeeContact",
          label: "Payee Contact",
          iconName: "",
          active: false,
          visible: false,
        },
        {
          id: 4,
          name: "payeeAddress",
          label: "Payee Address",
          iconName: "",
          active: false,
          visible: false,
        },
        {
          id: 5,
          name: "paymentInfo",
          label: "Payment Info",
          iconName: "",
          active: false,
          visible: false,
        },
      ],

      bankTabActive: false,
      contactTabActive: false,
      addressTabActive: false,

      paymentInfoTabActive: false,

      isEmpty: false,
      isHoverable: true,
      isBordered: false,
      isStriped: true,
      isFocusable: true,

      customerOfficeId: 0,
      customerId: 0,
      payeeList: [],
      payeeFilter: "",
      selectedPayee: { PayeeId: 0, PayeeName: "" },
      newPayee: { PayeeId: "", PayeeName: "" },
      isNewPayeeAdding: false,
      payeeDetails: null,

      // Bank Section start
      bankEditMenuOpen: false,
      selectedBankId: 0,
      selectedBank: {
        BankId: 0,
        AccountNumber: "",
        RoutingNumber: "",
        AccountType: "C",
      },
      // Bank Section end

      //Contact Section start
      contactEditMenuOpen: false,
      selectedContactId: 0,
      selectedContact: {
        ContactId: 0,
        Name: "",
        Phone: "",
        Email: "",
      },
      //Contact Section end

      //Address Section end
      addressEditMenuOpen: false,
      selectedAddressId: 0,
      selectedAddress: {
        AddressId: 0,
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Email: "",
        Phone: "",
      },
      paymentInfo: {
        AccountNumber: "",
        GlobalMemo: {
          Memo1: "",
          Memo2: "",
          Memo3: "",
        },
      },
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
  }),
  async mounted() {
    if (this.task == null) {
      this.$swal("Error!", "Could not get settlement details!", "error");
      this.closePanel();
      return;
    }
    this.selectedBank.AccountType = this.getAccountTypeKey(true);
    this.checkTabAuth();
    await this.$store.dispatch(globalTypes.GET_STATES);
    this.customerOfficeId = this.task.Creditor.Customer.OfficeId;
    this.customerId = this.task.Creditor.Customer.Id;
    this.paymentInfo.AccountNumber = this.task.GlobalMemo;
    this.payeeFilter = this.task.Payee;

    this.paymentInfo.GlobalMemo.Memo1 = this.task.GlobalMemo1;
  },
  methods: {
    getAccountTypeKey(isChecking){
      let rtn = "";
      if(isChecking){
        rtn = "C";
        if(this.task.PaymentProcessorId == 2){
          rtn = "CHK"
        }
        else if(this.task.PaymentProcessorId == 3){
          rtn="CHECKING";
        }
      }
      else{
        rtn = "S";
        if(this.task.PaymentProcessorId == 3){
          rtn = "SAVINGS";
        }
      }

      return rtn;

    },
    getPaymentTypeNames() {
      let rtn = "";
      if (this.task) {
        rtn = this.task.PaymentType.Name;
        if (
          this.task.IsTermSettlement &&
          this.task.PaymentDetails &&
          this.task.PaymentDetails.length > 0 &&
         this.task.PaymentProcessorId == 1
        ) {
          rtn = "";
          let paymentTypeIds = this.task.PaymentDetails.map(
            (x) => x.PaymentTypeId
          );
          if (paymentTypeIds) {
            for (let item of this.paymentTypeList) {
              if (paymentTypeIds.includes(item.Id)) {
                rtn += ", " + item.Name;
              }
            }

            if (rtn.length > 1) {
              rtn = rtn.substring(1, rtn.length);
            }
          }
        }
      }

      return rtn;
    },
    checkLength() {
      if (this.selectedAddress.Address1.length > 50) {
        this.selectedAddress.Address1 = this.selectedAddress.Address1.slice(
          0,
          50
        );
        this.isMaxLimitReachedAdress = true;
      } else {
        this.isMaxLimitReachedAdress = false;
      }

      if (this.selectedAddress.Address2.length > 50) {
        this.selectedAddress.Address2 = this.selectedAddress.Address2.slice(
          0,
          50
        );
        this.isMaxLimitReachedAdress2 = true;
      } else {
        this.isMaxLimitReachedAdress2 = false;
      }

      if (this.selectedAddress.City.length > 50) {
        this.selectedAddress.City = this.selectedAddress.City.slice(0, 50);
        this.isMaxLimitReachedCity = true;
      } else {
        this.isMaxLimitReachedCity = false;
      }
    },

    removeLeadingSpaces() {
      this.paymentInfo.AccountNumber =
        this.paymentInfo.AccountNumber.trimLeft();
      this.paymentInfo.GlobalMemo.Memo1 =
        this.paymentInfo.GlobalMemo.Memo1.trimLeft();
      this.paymentInfo.GlobalMemo.Memo2 =
        this.paymentInfo.GlobalMemo.Memo2.trimLeft();
      this.paymentInfo.GlobalMemo.Memo3 =
        this.paymentInfo.GlobalMemo.Memo3.trimLeft();
    },

    checkLengthAccountNumber() {
      if (this.paymentInfo.AccountNumber.length > 40) {
        this.paymentInfo.AccountNumber = this.paymentInfo.AccountNumber.slice(
          0,
          40
        );
        this.isCheckLengthAccountNumber = true;
      } else {
        this.isCheckLengthAccountNumber = false;
      }
    },

    checkLengthMemo1() {
      if (this.paymentInfo.GlobalMemo.Memo1.length > 40) {
        this.paymentInfo.GlobalMemo.Memo1 =
          this.paymentInfo.GlobalMemo.Memo1.slice(0, 40);
        this.isCheckMemo1 = true;
      } else {
        this.isCheckMemo1 = false;
      }
    },

    checkLengthMemo2() {
      if (this.paymentInfo.GlobalMemo.Memo2.length > 40) {
        this.paymentInfo.GlobalMemo.Memo2 =
          this.paymentInfo.GlobalMemo.Memo2.slice(0, 40);
        this.isCheckMemo2 = true;
      } else {
        this.isCheckMemo2 = false;
      }
    },

    checkLengthMemo3() {
      if (this.paymentInfo.GlobalMemo.Memo3.length > 40) {
        this.paymentInfo.GlobalMemo.Memo3 =
          this.paymentInfo.GlobalMemo.Memo3.slice(0, 40);
        this.isCheckMemo3 = true;
      } else {
        this.isCheckMemo3 = false;
      }
    },

    checkTabAuth() {
      let paymentTypesList = this.task.IsTermSettlement
        ? this.task.PaymentDetails.filter((x) => x.PaymentTypeId).map(
            (x) => x.PaymentTypeId
          )
        : [this.task.PaymentType.Id];

      this.bankTabActive = false;
      this.contactTabActive = false;
      this.addressTabActive = false;
      this.paymentInfoTabActive = false;

      this.tabs[1].visible = false;
      this.tabs[2].visible = false;
      this.tabs[3].visible = false;
      this.tabs[4].visible = false;

      if (this.task.IsDpg) {
        if (this.payeeDetails && this.payeeDetails.Banks) {
          this.bankTabActive =
            this.payeeDetails.Banks.length > 0
              ? this.selectedPayee.PayeeId > 0
              : true;
          this.tabs[1].visible = this.payeeDetails.Banks.length > 0;

          this.addressTabActive =
            this.payeeDetails.Banks.length > 0 ? this.selectedBankId > 0 : true;
          this.tabs[3].visible = true;
        } else {
          this.bankTabActive = false;
          this.tabs[1].visible = false;
          this.addressTabActive = true;
          this.tabs[3].visible = true;
        }

        this.paymentInfoTabActive = this.selectedAddressId > 0;

        this.tabs[4].visible = true;
      }
      else if(this.task.PaymentProcessorId ==3){
        this.tabs[1].visible = true;
        this.tabs[2].visible = true;
        this.tabs[3].visible = true;
        this.tabs[4].visible = true;

        this.bankTabActive =
                this.tabs[1].visible && this.selectedPayee.PayeeId > 0;
        this.contactTabActive= this.selectedPayee.PayeeId > 0 && this.selectedBankId>0;
        this.addressTabActive =
                this.selectedPayee.PayeeId > 0 && this.selectedBankId>0;

        this.paymentInfoTabActive = this.selectedBankId > 0;

      }
      else {
        if (paymentTypesList.includes(PAYMENT_TYPES.ACH)) {
          this.tabs[1].visible = true;
          this.tabs[4].visible = true;
        }
        if (paymentTypesList.includes(PAYMENT_TYPES.ManualCheck)) {
          this.tabs[3].visible = true;
          this.tabs[4].visible = true;
        }
        if (
          paymentTypesList.every((ptype) => ptype == PAYMENT_TYPES.CheckByPhone)
        ) {
          this.tabs[1].visible = false;
          this.tabs[4].visible = true;
        }
        if (paymentTypesList.includes(PAYMENT_TYPES.SecondDayCheck)) {
          this.tabs[3].visible = true;
          this.tabs[4].visible = true;
        }
        if (paymentTypesList.includes(PAYMENT_TYPES.OverniteCheck)) {
          this.tabs[3].visible = true;
          this.tabs[4].visible = true;
        }
        if (paymentTypesList.includes(PAYMENT_TYPES.WireTransfer)) {
          this.tabs[1].visible = true;
          this.tabs[3].visible = true;
          this.tabs[4].visible = true;
        }
        if (
          paymentTypesList.every(
            (ptype) => ptype == PAYMENT_TYPES.GlobalDirctPay
          )
        ) {
          this.tabs[1].visible = false;
          this.tabs[4].visible = true;
        }

        this.bankTabActive =
          this.tabs[1].visible && this.selectedPayee.PayeeId > 0;
        this.addressTabActive =
          this.tabs[3].visible &&
          (this.bankTabActive
            ? this.selectedBankId > 0
            : this.selectedPayee.PayeeId > 0);

        if (this.addressTabActive) {
          this.paymentInfoTabActive = this.selectedAddressId > 0;
        } else if (this.bankTabActive) {
          this.paymentInfoTabActive = this.selectedBankId > 0;
        } else {
          this.paymentInfoTabActive = this.selectedPayee.PayeeId > 0;
        }
      }
    },
    async searchPayee() {
      let officeId = this.task.Creditor.Customer.OfficeId;
      let payeeName = this.payeeFilter;
      if (officeId > 0 && payeeName.trim().length > 0) {
        let err, result;
        this.isLoading = true;
        [err, result] = await this.$store.dispatch(types.SEARCH_PAYEE, {
          OfficeId: officeId,
          CustomerId: this.customerId,
          IsDirectPay: this.task.PaymentType.Id == 10 ? true : false,
          Name: payeeName,
        });
        this.isLoading = false;

        if (result) {
          this.isNewPayeeAdding = false;
          if (result.Data && result.Data.length > 0) {
            this.payeeList = result.Data;
          } else {
            this.$swal(
              "Result",
              "No records found for " + payeeName,
              "Warning"
            );
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        this.$swal(
          "Warning!",
          "OfficeId and Payee Name parameters is required.",
          "warning"
        );
      }
    },
    async showNewPayeePanel() {
      this.payeeList = [];
      this.isNewPayeeAdding = true;
    },
    async payeeNameEnter(event) {
      if (event.keyCode == 13) {
        await this.searchPayee();
      }
    },
    async selectPayee(item) {
      this.selectedPayee.PayeeId = item.PayeeId;
      this.selectedPayee.PayeeName = item.PayeeName;
      this.selectedBankId = 0;
      this.selectedContactId = 0;
      this.selectedAddressId = 0;
      this.payeeList = [];
      this.isLoading = true;
      await this.getPayeeDetails();
      this.isLoading = false;
      this.checkTabAuth();
    },
    async addNewPayee() {
      let payeeId = this.newPayee.PayeeId;
      let payeeName = this.newPayee.PayeeName;
      let officeId = this.task.Creditor.Customer.OfficeId;
      if (payeeId.trim().length > 0 && payeeName.trim().length > 0) {
        let err, result;

        [err, result] = await this.$store.dispatch(types.ADD_NEW_PAYEE, {
          CustomerId: this.customerId,
          OfficeId: officeId,
          PayeeId: payeeId,
          Name: payeeName,
        });
        if (result) {
          this.selectedPayee.PayeeId = result.Data.PayeeId;
          this.selectedPayee.PayeeName = result.Data.PayeeName;
          this.isNewPayeeAdding = false;
          this.payeeFilter = "";
          await this.getPayeeDetails();
          this.checkTabAuth();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    cancelNewPayee() {
      this.newPayee.PayeeId = "";
      this.newPayee.PayeeName = "";
      this.isNewPayeeAdding = false;
    },
    async getPayeeDetails() {
      let err, result;

      [err, result] = await this.$store.dispatch(types.GET_PAYEE_DETAILS, {
        CustomerId: this.customerId,
        OfficeId: this.task.Creditor.Customer.OfficeId,
        PayeeId: this.selectedPayee.PayeeId,
        IsDirectPay: this.task.PaymentType.Id == 10 ? true : false,
      });
      if (result) {
        this.payeeDetails = result.Data;
        this.checkTabAuth();
      } else {
        let errMsg = "An Error Occured!";
        if (err) {
          if (err.response.data.Errors)
            errMsg = err.response.data.Errors.join(",");
        }
        this.$swal("Error!", errMsg, "error");
      }
    },

    //Bank Section Start
    async selectBank(item) {
      this.selectedBankId = item.BankId;
      this.checkTabAuth();
    },
    editBank(item) {
      this.selectedBank.BankId = item.BankId;
      this.selectedBank.AccountNumber = item.AccountNumber;
      this.selectedBank.RoutingNumber = item.RoutingNumber;
      this.selectedBank.AccountType = item.AccountType;
      this.bankEditMenuOpen = true;

      let isChecking = false;
      if(item.AccountType != null && item.AccountType.toLowerCase().startsWith("c"))
      {
         isChecking = true;
      }
      this.selectedBank.AccountType = this.getAccountTypeKey(isChecking);
    },
    deleteBank(id) {
      this.$swal({
        title: "CONFIRM",
        text: "Are you sure to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          let err, result;
          [err, result] = await this.$store.dispatch(types.REMOVE_BANK, {
            CustomerId: this.customerId,
            OfficeId: this.customerOfficeId,
            PayeeId: this.selectedPayee.PayeeId,
            BankId: id,
          });

          if (result) {
            this.$swal("Success", "", "success");
            if (this.selectedBankId == id) {
              this.selectedBankId = 0;
              this.handleTabChange();
            }
            this.bankEditMenuOpen = false;
            await this.getPayeeDetails();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },
    addBank() {
      this.selectedBank = {
        BankId: 0,
        RoutingNumber: "",
        AccountNumber: "",
        AccountType: "C",
      };
      this.bankEditMenuOpen = true;
      this.selectedBank.AccountType = this.getAccountTypeKey(true);
    },
    async saveBank() {
      if (
        this.selectedBank.AccountNumber != "" &&
        this.selectedBank.RoutingNumber != ""
      ) {
        let err, result;

        let payload = {
          CustomerId: this.customerId,
          OfficeId: this.customerOfficeId,
          PayeeId: this.selectedPayee.PayeeId,
          RoutingNumber: this.selectedBank.RoutingNumber,
          AccountNumber: this.selectedBank.AccountNumber,
          AccountType: this.selectedBank.AccountType,
        };

        let payloadType = "";
        if (this.selectedBank.BankId != 0) {
          payload.BankId = this.selectedBank.BankId;
          payloadType = types.UPDATE_BANK;
        } else {
          payloadType = types.ADD_NEW_BANK;
        }

        if (payloadType == types.ADD_NEW_BANK && this.task.IsDpg == true) {
          return;
        }

        [err, result] = await this.$store.dispatch(payloadType, payload);

        if (result) {
          this.$swal("Success", "", "success");
          this.bankEditMenuOpen = false;
          await this.getPayeeDetails();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    cancelBank() {
      this.bankEditMenuOpen = false;
    },
    //Bank Section End

    //Contact Section Start
    selectContact(item) {
      this.selectedContactId = item.ContactId;
      this.checkTabAuth();
    },
    addContact() {
      this.selectedContact = {
        ContactId: 0,
        Name: "",
        Phone: "",
        Email: "",
      };
      this.contactEditMenuOpen = true;
    },
    editContact(item) {
      this.selectedContact.ContactId = item.ContactId;
      this.selectedContact.Name = item.Name;
      this.selectedContact.Phone = item.Phone;
      this.selectedContact.Email = item.Email;
      this.contactEditMenuOpen = true;
    },
    deleteContact(id) {
      this.$swal({
        title: "CONFIRM",
        text: "Are you sure to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          let err, result;
          [err, result] = await this.$store.dispatch(types.REMOVE_CONTACT, {
            CustomerId: this.customerId,
            OfficeId: this.customerOfficeId,
            PayeeId: this.selectedPayee.PayeeId,
            ContactId: id,
          });

          if (result) {
            this.$swal("Success", "", "success");
            this.contactEditMenuOpen = false;
            if (this.selectedContactId == id) {
              this.selectedContactId = 0;
              this.handleTabChange();
            }
            await this.getPayeeDetails();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },
    async saveContact() {
      if (
        this.selectedContact.Name != "" &&
        this.selectedContact.Phone != "" &&
        this.selectedContact.Email != ""
      ) {
        let err, result;

        let payload = {
          CustomerId: this.customerId,
          OfficeId: this.customerOfficeId,
          PayeeId: this.selectedPayee.PayeeId,
          Name: this.selectedContact.Name,
          Phone: this.selectedContact.Phone,
          Email: this.selectedContact.Email,
        };

        let payloadType = "";
        if (this.selectedContact.ContactId != 0) {
          payload.ContactId = this.selectedContact.ContactId;
          payloadType = types.UPDATE_CONTACT;
        } else {
          payloadType = types.ADD_NEW_CONTACT;
        }
        [err, result] = await this.$store.dispatch(payloadType, payload);

        if (result) {
          this.$swal("Success", "", "success");
          this.contactEditMenuOpen = false;
          await this.getPayeeDetails();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    cancelContact() {
      this.contactEditMenuOpen = false;
    },
    //Contact Section End

    //Address Section Start
    selectAddress(item) {
      this.selectedAddressId = item.AddressId;
      this.checkTabAuth();
    },
    addAddress() {
      this.selectedAddress = {
        AddressId: 0,
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Phone: "",
        Email: "",
      };
      this.addressEditMenuOpen = true;
    },
    async editAddress(item) {
      this.selectedAddress.AddressId = item.AddressId;
      this.selectedAddress.Address1 = item.Address1;
      this.selectedAddress.Address2 = item.Address2;
      this.selectedAddress.City = item.City;
      this.selectedAddress.State = item.State;
      this.selectedAddress.Zip = item.Zip;
      this.addressEditMenuOpen = true;
    },
    deleteAddress(id) {
      this.$swal({
        title: "CONFIRM",
        text: "Are you sure to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          let err, result;
          [err, result] = await this.$store.dispatch(types.REMOVE_ADDRESS, {
            CustomerId: this.customerId,
            OfficeId: this.customerOfficeId,
            PayeeId: this.selectedPayee.PayeeId,
            AddressId: id,
          });

          if (result) {
            this.$swal("Success", "", "success");
            this.addressEditMenuOpen = false;
            if (this.selectedAddressId == id) {
              this.selectedAddressId = 0;
              this.handleTabChange();
            }
            await this.getPayeeDetails();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },
    async saveAddress() {
      let requestValid = false;
      if (this.task.IsDpg) {
        requestValid =
          this.selectedAddress.Address1 != "" &&
          this.selectedAddress.State != "" &&
          this.selectedAddress.Zip != "" &&
          this.selectedAddress.Phone != "" &&
          this.selectedAddress.Email != "";
      }
      else if(this.task.PaymentProcessorId == 3){
        requestValid =
        this.selectedAddress.Address1 != "" &&
        this.selectedAddress.City != "" &&
        this.selectedAddress.State != "" &&
        this.selectedAddress.Zip != "";
      }
      else {
        requestValid =
          this.selectedAddress.Address1 != "" &&
          this.selectedAddress.State != "" &&
          this.selectedAddress.Zip != "" &&
          this.selectedAddress.City != "";
      }

      if (requestValid) {
        let err, result;

        let payload = {
          CustomerId: this.customerId,
          OfficeId: this.customerOfficeId,
          PayeeId: this.selectedPayee.PayeeId,
          Address1: this.selectedAddress.Address1,
          Address2: this.selectedAddress.Address2,
          City: this.selectedAddress.City,
          Zip: this.selectedAddress.Zip,
          State: this.selectedAddress.State,
          Email: this.selectedAddress.Email,
          Phone: this.selectedAddress.Phone,
        };

        let payloadType = "";
        if (this.selectedAddress.AddressId != 0) {
          payload.AddressId = this.selectedAddress.AddressId;
          payloadType = types.UPDATE_ADDRESS;
        } else {
          payloadType = types.ADD_NEW_ADDRESS;
        }
        [err, result] = await this.$store.dispatch(payloadType, payload);

        if (result) {
          this.$swal("Success", "", "success");
          this.addressEditMenuOpen = false;
          await this.getPayeeDetails();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        let validationMessage ="";

         if(this.task.PaymentProcessorId == 2){
          validationMessage =  "Address1, State, Zip, Phone and Email required.";
        }
         else  if(this.task.PaymentProcessorId == 3){
           validationMessage =  "Address1, State, Zip and Phone required.";
         }
      else{
          validationMessage =  "Address1, State, City and Zip required.";
        }
        this.$swal("Warning!", validationMessage, "warning");
      }
    },
    cancelAddress() {
      this.addressEditMenuOpen = false;
    },
    //Address Section End

    //Payment Info Section Start
    async savePaymentInfo() {
      this.isLoading = true;
      let payments = [];
      if (this.task.PaymentDetails.length == 0) {
        payments.push({
          PaymentDate: new Date(this.task.PaymentDay).toString("M/d/yyyy"),
          PaymentAmount: this.task.Offer,
          FeeAmount: this.task.SettlementFee,
        });
      }

      let payload = {
        CreditorId: this.task.Creditor.Id,
        CustomerId: this.task.Creditor.Customer.Id,
        OfficeId: this.customerOfficeId,
        PaymentTypeId: this.task.PaymentType.Id,
        Payee: {
          PayeeId: this.selectedPayee.PayeeId,
          BankId: this.selectedBankId,
          ContactId: this.selectedContactId,
          AddressId: this.selectedAddressId,
          AccountNumber: this.paymentInfo.AccountNumber,
        },
        Payments:
          this.task.PaymentDetails.length > 0
            ? this.task.PaymentDetails
            : payments,
        GlobalMemo: this.paymentInfo.GlobalMemo,
        IsDpgCustomer: this.task.IsDpg ? this.task.IsDpg : false,
        PaymentProcessorId:this.task.PaymentProcessorId,
      };

      let err, result;

      [err, result] = await this.$store.dispatch(types.ADD_SETTLEMENT, payload);
      this.isLoading = false;

      if (result) {
        this.$swal("Success", result.Message, "success");
        await this.approveTask();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    //Payment Info Section End

    async approveTask() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.ATTORNEY_TASK_APPROVE, {
        Id: this.task.Id,
      });

      if (result) {
        this.closePanel({ Approved: true });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    closePanel(data) {
      this.$emit("closePaymentSetupPopUp", data);
    },

    handleTabChange(tabName) {
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name === tabName ? true : false;
        return item;
      });
      this.tabs = [...temp];
    },
    phoneChanged(event) {
      if (event.target.value) {
        this.selectedContact.Phone = event.target.value;
      }
    },
    phoneChangedDpg(event) {
      if (event.target.value) {
        this.selectedAddress.Phone = event.target.value;
      }
    },
    zipChanged(event) {
      if (event.target.value) {
        this.selectedAddress.Zip = event.target.value;
      }
    },
  },
  validations() {
    return {
      paymentInfo: {
        AccountNumber: { required },
      },
    };
  },
};
</script>

<style scoped>
.closeButtonDR {
  position: absolute;
  top: -0px;
  right: 10px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}

.closeButtonDR:hover {
  color: #ff3860;
}
.tab-item .active {
  background-color: #3bb0db;
  color: #efefef !important;
}
.sub-info {
  font-size: 10px;
  color: #999;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
