<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div style="display: flex; justify-content: space-between">
      <label>{{
        creditorId > 0 ? "Add Bulk Payment" : "Payment Changes"
      }}</label>
      <i
        v-on:click.prevent.stop="closePanel"
        class="fas fa-window-close closeButtonBulk"
      ></i>
    </div>
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 275px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      class="col-md-12 row"
      v-if="paymentObj == null && creditorList.length > 0"
    >
      <div class="col-md-12 mb-2">
        <label class="generalLabel">Creditor: </label>
        <select
          @change="creditorChange(paymentChangeData.CreditorId)"
          v-model="paymentChangeData.CreditorId"
          :disabled="creditorId > 0"
          :class="creditorId > 0 ? 'readonlyInput' : ''"
          class="form-control"
        >
          <option :value="0">Please Select</option>
          <option v-for="item in creditorList" :value="item.Id" :key="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="col-md-12 noPadding text-center"
      v-if="paymentObj == null && creditorList.length == 0"
    >
      <label class="generalLabel text-danger pt-3 pb-3"
        >No settled creditor available to add payment.
      </label>
    </div>
    <perfect-scrollbar style="max-height: 500px; overflow-x: hidden">
      <div
        class="col-md-12 row"
        v-if="
          (paymentObj == null && paymentChangeData.CreditorId > 0) ||
          paymentObj != null
        "
      >
        <div class="col-md-4">
          <label class="generalLabel">Payee</label>
          <input
            type="text"
            v-model="payment.Payee"
            placeholder="Payee"
            :disabled="true"
            class="form-control readonlyInput"
          />
        </div>
        <div class="col-md-4" v-if="payment">
          <label class="generalLabel">Effective Date</label>
          <DatePicker
            mode="date"
            :locale="'en-US'"
            v-model="payment.EffectiveDate"
            :model-config="modelConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="Select Date"
              />
            </template>
          </DatePicker>
        </div>
        <div class="col-md-4" v-if="payment">
          <label class="generalLabel">Account Number</label>
          <input
            type="text"
            v-model="payment.AccountNumber"
            placeholder="Account Number"
            class="form-control"
          />
        </div>
      </div>

      <div
        class="col-md-12 row mt-2"
        v-if="
          (paymentObj == null && paymentChangeData.CreditorId > 0) ||
          paymentObj != null
        "
      >
        <div class="col-md-4">
          <label class="generalLabel">Payment Type</label>
          <select
            @change="paymentTypeChanged"
            v-model="payment.PaymentType"
            class="form-control"
            :disabled="previousPayment"
            :class="previousPayment ? 'readonlyInput' : ''"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in paymentTypes"
              :value="item.Id"
              :key="item.Id"
            >
              {{ item.Name}}
            </option>
          </select>
        </div>
        <div class="col-md-4" v-if="payment">
          <label class="generalLabel">Payment Amount</label>
          <input
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
            }"
            placeholder="$0.00"
            v-model="payment.PaymentAmount"
          />
        </div>
        <div
          class="col-md-4"
          v-if="payment && payment.FeeAmount > 0 && isDpg == false"
        >
          <label class="generalLabel">Fee Amount</label>
          <input
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
            }"
            placeholder="$0.00"
            v-model="payment.FeeAmount"
          />
        </div>

        <div class="col-md-4" v-if="creditorId > 0">
          <label for="serviceFee" class="generalLabel">Payment Count:</label>
          <input
            v-model="payment.PaymentCount"
            class="form-control"
            v-myMask="{
              alias: 'numeric',
              min: 1,
              max: 120,
              digits: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>

        <div class="col-md-4" v-if="creditorId">
          <label class="generalLabel">Schedule Type:</label>

          <select class="form-control" v-model="payment.ScheduleType">
            <option v-for="item in schedules" :value="item.Id" :key="item.Id">
              {{ item.Value }}
            </option>
          </select>
        </div>
        <div class="col-md-3" v-if="payment && payment.ScheduleType == 2">
          <label class="generalLabel">Day:</label>
          <CustomNumericInput
            class="generalTextBox ml-1"
            :value="twiceAmonthDay"
            @updateValue="updateTwiceAMonthDay"
            :minNumber="1"
            :maxNumber="getMaxDayofMonth(payment.EffectiveDate)"
            placeholder="Day"
          />
        </div>
      </div>
      <div
        v-if="
          (paymentObj == null && paymentChangeData.CreditorId > 0) ||
          paymentObj != null
        "
        class="col-md-12 row mt-4"
      >
        <ul class="nav nav-tabs" style="width: 100%">
          <li v-for="tab in tabs" :key="tab.id" class="nav-item tab-item">
            <a
              v-if="tab.visible"
              data-toggle="tab"
              :aria-expanded="tab.active"
              v-on:click.prevent="() => handleTabChange(tab.name)"
              class="tab-link"
              :class="{ active: tab.active }"
            >
              <span class="d-sm-inline-block tab-item-label">
                {{ tab.label }}
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div
        class="col-md-12"
        v-if="
          (paymentObj == null && paymentChangeData.CreditorId > 0) ||
          paymentObj != null
        "
      >
        <div
          v-show="tabs[0].active && previousPayment == null"
          class="form-group row"
        >
          <div class="col-md-12" v-if="payment.PaymentType">
            <label for="selectPayee" class="generalLabel">
              {{
                selectedPayee.PayeeId == 0
                  ? "Select or add payee:"
                  : "Payee Name:"
              }}
              <span style="color: green">{{ selectedPayee.PayeeName }}</span>
            </label>
          </div>
          <div
            v-if="
              (selectedPayee.PayeeId == 0 || paymentObj == null) &&
              payment.PaymentType
            "
            class="col-md-12 noPadding"
            style="display: flex; align-items: center"
          >
            <div class="col-md-2">
              <label for="payeeName" class="generalLabel">Payee Name</label>
            </div>
            <div class="col-md-5">
              <input
                type="text"
                id="payeeNameInp"
                class="generalTextBox form-control"
                autocomplete="off"
                placeholder="Please type a payee name"
                v-model="payeeFilter"
                @keypress="payeeNameEnter"
              />
            </div>
            <div class="col-md-5">
              <button
                type="button"
                class="btn btn-outline-info waves-effect waves-light btn-sm"
                style="border-radius: 5px; padding: 8px 12px"
                @click="searchPayee()"
                :disabled="payeeFilter.trim().length == 0"
              >
                <i class="fas fa-search"></i>
              </button>
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light ml-2"
                style="border-radius: 5px"
                @click="showNewPayeePanel()"
              >
                Add New
              </button>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="
              !isNewPayeeAdding &&
              (selectedPayee.PayeeId == 0 || paymentObj == null)
            "
          >
            <div class="offset-2 col-md-5">
              <perfect-scrollbar
                v-if="payeeList.length > 0"
                style="padding: 10px; height: 120px; background-color: #efefef"
              >
                <p
                  @click="selectPayee(item)"
                  v-for="item in payeeList"
                  :key="item.PayeeId"
                  style="cursor: pointer"
                >
                  {{ item.PayeeName }}
                </p>
              </perfect-scrollbar>
            </div>
          </div>
          <div
            class="col-md-12 row"
            v-if="
              isNewPayeeAdding &&
              (selectedPayee.PayeeId == 0 || paymentObj == null)
            "
          >
            <div class="col-md-6">
              <label class="generalLabel">Payee</label>
              <input
                type="text"
                id="payeeInp"
                class="generalTextBox form-control"
                placeholder="Please type a payee"
                v-model="newPayee.PayeeName"
              />
            </div>
            <div class="col-md-6">
              <label class="generalLabel">DRC Payee ID</label>
              <input
                type="text"
                id="payeeInp"
                class="generalTextBox form-control"
                placeholder="Please type a DRC Payee ID"
                v-model="newPayee.PayeeId"
              />
            </div>
            <div
              class="col-md-12"
              style="display: flex; justify-content: flex-end"
            >
              <button
                :disabled="
                  newPayee.PayeeId.trim().length == 0 ||
                  newPayee.PayeeName.trim().length == 0
                "
                @click="addNewPayee()"
                type="button"
                class="btn btn-success waves-effect waves-light col-md-2"
              >
                Confirm
              </button>
              <button
                @click="cancelNewPayee()"
                type="button"
                class="btn btn-danger waves-effect waves-light ml-2 col-md-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div
          v-show="tabs[1].active && previousPayment == null"
          class="form-group row"
        >
          <div v-if="!bankTabActive" class="col-md-12">
            <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
              You must fill previous tab data to use this section.
            </div>
          </div>
          <div v-if="bankTabActive && selectedBankId > 0" class="col-md-12">
            <div class="text-white bg-success pt-1 pl-2 pr-2 pb-1">
              Bank Id: {{ selectedBankId }} selected.
            </div>
          </div>

          <div
            v-if="bankTabActive && selectedBankId == 0"
            class="sub-info ml-3"
          >
            Double click to select on grid
          </div>

          <div class="col-md-12" v-if="bankTabActive">
            <button
              v-if="
                payeeDetails && payeeDetails.Banks && !settlementDetails.IsDpg
              "
              @click="addBank()"
              class="btn btn-sm generalButton"
              style="
                background-color: #1abc9c;
                color: #ffffff;
                position: absolute;
                top: 12px;
                right: 20px;
                z-index: 10;
              "
            >
              Add new
            </button>
            <perfect-scrollbar
              v-if="payeeDetails && payeeDetails.Banks"
              class="col-md-12 datagrid-table"
              style="max-height: 180px; cursor: pointer"
            >
              <o-table
                :data="isEmpty ? [] : payeeDetails.Banks"
                @dblclick="selectBank($event)"
              >
                <o-table-column
                  field="RoutingNumber"
                  label="Routing Number"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.RoutingNumber }}
                </o-table-column>

                <o-table-column
                  field="AccountNumber"
                  label="Account Number"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.AccountNumber }}
                </o-table-column>

                <o-table-column
                  field="AccountType"
                  label="Account Type"
                  sortable
                  position="centered"
                  v-slot="props"
                >
                  <span style="text-align: center">
                    {{ props.row.AccountType }}
                  </span>
                </o-table-column>

                <o-table-column field="edit" v-slot="props">
                  <i
                    @click="editBank(props.row)"
                    class="fas fa-pen"
                    style="
                      color: rgb(29, 161, 242);
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>
                </o-table-column>
                <o-table-column
                  field="delete"
                  v-slot="props"
                  v-if="payeeDetails && !settlementDetails.IsDpg"
                >
                  <i
                    @click="deleteBank(props.row.BankId)"
                    class="fas fa-times"
                    style="
                      color: red;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>
                </o-table-column>
              </o-table>
            </perfect-scrollbar>
            <div
              v-if="bankEditMenuOpen"
              class="col-md-12"
              style="background-color: #efefef; padding: 5px 0"
            >
              <div class="row">
                <div class="col-md-3">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="bankAccountNumber"
                      >Account Number</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="bankAccountNumber"
                      class="generalTextBox"
                      placeholder="Account Number"
                      v-model="selectedBank.AccountNumber"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="bankRoutingNumber"
                      >Routing Number</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="bankRoutingNumber"
                      class="generalTextBox"
                      placeholder="Routing Number"
                      v-model="selectedBank.RoutingNumber"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="bankRoutingNumber"
                      >Account Type</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="selectedBank.AccountType"
                      id="slcAccountType"
                      class="generalSelectBox form-control"
                    >
                      <option :value="settlementDetails.IsDpg ? 'CHK' : 'C'">
                        Checking
                      </option>
                      <option :value="settlementDetails.IsDpg ? 'S' : 'S'">
                        Savings
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="bankRoutingNumber"></label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <button
                      @click="saveBank()"
                      :disabled="
                        selectedBank.AccountNumber.trim().length == '' ||
                        selectedBank.RoutingNumber.trim().length == ''
                      "
                      class="btn btn-sm generalButton"
                      style="
                        background-color: #3bafda;
                        color: #ffffff;
                        margin-top: 2px;
                      "
                    >
                      {{ selectedBank.BankId == 0 ? "Add" : "Save" }}
                    </button>
                    <button
                      @click="cancelBank()"
                      class="btn btn-sm generalButton"
                      style="
                        background-color: #ed3030;
                        color: #ffffff;
                        margin-top: 2px;
                      "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tabs[2].active && previousPayment == null"
          class="form-group row"
        >
          <div v-if="!addressTabActive" class="col-md-12">
            <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
              You must fill previous tab data to use this section.
            </div>
          </div>
          <div
            v-if="addressTabActive && selectedAddressId > 0"
            class="col-md-12"
          >
            <div class="text-white bg-success pt-1 pl-2 pr-2 pb-1">
              Address Id: {{ selectedAddressId }}
            </div>
          </div>

          <div
            v-if="addressTabActive && selectedAddressId == 0"
            class="sub-info ml-3"
          >
            Double click to select on grid
          </div>
          <div v-if="addressTabActive" class="col-md-12">
            <button
              v-if="payeeDetails && payeeDetails.Addresses"
              @click="addAddress()"
              type="button"
              class="btn btn-xs generalButton"
              style="
                background-color: #1abc9c;
                color: #ffffff;
                position: absolute;
                top: 10px;
                right: 15px;
                z-index: 10;
              "
            >
              Add new
            </button>

            <perfect-scrollbar
              v-if="payeeDetails && payeeDetails.Addresses"
              class="col-md-12 datagrid-table"
              style="max-height: 180px; cursor: pointer"
            >
              <o-table
                :data="isEmpty ? [] : payeeDetails.Addresses"
                @dblclick="selectAddress($event)"
              >
                <o-table-column
                  field="Address1"
                  label="Address 1"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Address1 }}
                </o-table-column>

                <o-table-column
                  field="Address2"
                  label="Address 2"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Address2 }}
                </o-table-column>

                <o-table-column
                  field="City"
                  label="City/Municipality"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.City }}
                </o-table-column>

                <o-table-column field="State" label="State" v-slot="props">
                  {{ props.row.State }}
                </o-table-column>
                <o-table-column field="Zip" label="Zip Code" v-slot="props">
                  {{ props.row.Zip }}
                </o-table-column>
                <o-table-column
                  field="Email"
                  label="Email"
                  v-slot="props"
                  v-if="settlementDetails.IsDpg"
                >
                  {{ props.row.Email }}
                </o-table-column>
                <o-table-column
                  field="Phone"
                  label="Phone"
                  v-slot="props"
                  v-if="settlementDetails.IsDpg"
                >
                  {{ props.row.Phone }}
                </o-table-column>
                <o-table-column
                  field="edit"
                  v-slot="props"
                  v-if="payeeDetails && !settlementDetails.IsDpg"
                >
                  <i
                    @click="editAddress(props.row)"
                    class="fas fa-pen"
                    style="
                      color: rgb(29, 161, 242);
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>
                </o-table-column>
                <o-table-column
                  field="delete"
                  v-slot="props"
                  v-if="payeeDetails && !settlementDetails.IsDpg"
                >
                  <i
                    @click="deleteAddress(props.row.AddressId)"
                    class="fas fa-times"
                    style="
                      color: red;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>
                </o-table-column>
              </o-table>
            </perfect-scrollbar>
            <div
              v-if="addressEditMenuOpen"
              class="col-md-12"
              style="background-color: #efefef; padding: 5px 0"
            >
              <div class="row">
                <div class="col-md-4">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="address1">Address 1</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="address1"
                      class="generalTextBox"
                      placeholder="Address 1"
                      style="width: 100%"
                      v-model="selectedAddress.Address1"
                      v-on:input="checkLength"
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="isMaxLimitReachedAdress"
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="address2">Address 2</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="address2"
                      class="generalTextBox"
                      placeholder="Address 2"
                      style="width: 100%"
                      v-model="selectedAddress.Address2"
                      v-on:input="checkLength"
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="isMaxLimitReachedAdress2"
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="payeeDetails && !settlementDetails.IsDpg"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="addressCity">City</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="addressCity"
                      class="generalTextBox"
                      placeholder="City"
                      style="width: 100%"
                      v-model="selectedAddress.City"
                      v-on:input="checkLength"
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="isMaxLimitReachedCity"
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="contactEmail">State</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="selectedAddress.State"
                      id="State"
                      class="generalSelectBox form-control"
                    >
                      <option value="">Select</option>
                      <option
                        v-for="item in states"
                        :key="item.Id"
                        :value="item.ShortCode"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="addressZip">Zip</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="addressZip"
                      class="generalTextBox"
                      placeholder="Zip"
                      v-myMask="{ mask: ['99999'] }"
                      v-model="selectedAddress.Zip"
                      @change="zipChanged"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="payeeDetails && settlementDetails.IsDpg"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="contactPhone">Phone</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="contactPhone"
                      class="generalTextBox"
                      placeholder="Phone"
                      v-myMask="{ mask: ['(999) 999-9999'] }"
                      v-model="selectedAddress.Phone"
                      @change="phoneChangedDpg"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="payeeDetails && settlementDetails.IsDpg"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="contactEmail">Email</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      id="contactEmail"
                      style="width: 100%"
                      class="generalTextBox"
                      placeholder="Email"
                      v-model="selectedAddress.Email"
                    />
                  </div>
                </div>
                <div class="col-md-12 text-right noMargin">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel"></label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <button
                      @click="saveAddress()"
                      class="btn btn-sm generalButton"
                      style="
                        background-color: #3bafda;
                        color: #ffffff;
                        margin-top: 2px;
                      "
                    >
                      {{ selectedAddress.AddressId == 0 ? "Add" : "Save" }}
                    </button>
                    <button
                      @click="cancelAddress()"
                      class="btn btn-sm generalButton"
                      style="
                        background-color: #ed3030;
                        color: #ffffff;
                        margin-top: 2px;
                      "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="tabs[3].active" class="form-group row">
          <div v-if="!paymentInfoTabActive" class="col-md-12">
            <div class="text-white bg-danger pt-2 pl-2 pr-2 pb-2">
              You must fill previous tab data to use this section.
            </div>
          </div>
          <div v-if="paymentInfoTabActive" class="col-md-12 row">
            <div class="col-md-6 mb-2">
              <label for="accountNumber" class="generalLabel"
                >Account Number</label
              >
              <input
                type="text"
                id="accountNumberInp"
                class="generalTextBox form-control"
                placeholder="Please type a account number"
                v-model="payment.AccountNumber"
              />
            </div>
            <div class="col-md-6 mb-2">
              <label for="accountNumber" class="generalLabel"
                >Global Memo 1</label
              >
              <input
                type="text"
                id="accountNumberInp"
                class="generalTextBox form-control"
                placeholder="Please type a global memo 1"
                v-model="paymentChangeData.GlobalMemo1"
              />
            </div>
            <div class="col-md-6 mb-2">
              <label for="accountNumber" class="generalLabel"
                >Global Memo 2</label
              >
              <input
                type="text"
                id="accountNumberInp2"
                class="generalTextBox form-control"
                placeholder="Please type a global memo 2"
                v-model="paymentChangeData.GlobalMemo2"
              />
            </div>
            <div class="col-md-6 mb-2">
              <label for="accountNumber" class="generalLabel"
                >Global Memo 3</label
              >
              <input
                type="text"
                id="accountNumberInp3"
                class="generalTextBox form-control"
                placeholder="Please type a global memo 3"
                v-model="paymentChangeData.GlobalMemo3"
              />
            </div>
            <div
              v-if="checkAuth(54983) && isDpg == false && creditorId == 0"
              class="col-md-12 row mt-2"
            >
              <div
                class="col-md-12 row pt-2"
                style="border-top: 1px solid #ededed"
              >
                <label class="generalLabel">Extra Fees:</label>
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Fee Type</label>
                <select class="form-control" v-model="extraFees.GlobalFeeType">
                  <option :value="null">Please Select</option>
                  <option
                    v-for="item in feesTypesList"
                    :value="item.FeeType"
                    :key="item.FeeType"
                  >
                    {{ item.Description }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Amount</label>
                <input
                  v-model="extraFees.FeeAmount"
                  class="form-control"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  placeholder="$0.00"
                />
              </div>
              <div class="col-md-3">
                <label class="generalLabel">Date</label>

                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="extraFees.FeeDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div
                class="col-md-1"
                style="padding-top: 29px; margin-left: -9px"
              >
                <button
                  @click="addExtraFee()"
                  type="button"
                  class="btn btn-success waves-effect waves-light"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>

              <perfect-scrollbar
                style="
                  width: 100%;
                  max-height: 150px;
                  overflow-x: hidden !important;
                "
              >
                <div class="col-md-12 pt-3">
                  <label
                    v-for="(item, index) in paymentChangeData.ApplicableFees"
                    :key="index"
                  >
                    <div style="display: flex; align-items: stretch">
                      <label class="generalLabel pr-2"
                        >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                          item.GlobalFeeType
                        }}</label></label
                      >
                      <label class="generalLabel pr-2"
                        >Amount:<label class="ml-1" style="color: #6c757d">{{
                          formatMoney(item.FeeAmount)
                        }}</label></label
                      >
                      <label class="generalLabel pr-1"
                        >Date:<label class="ml-1" style="color: #6c757d">{{
                          item.FeeDate
                        }}</label></label
                      >

                      <span
                        style="padding: 5px; cursor: pointer; height: 20px"
                        class="badge badge-danger"
                        @click="deleteExtraFee(index)"
                      >
                        Cancel</span
                      >
                    </div>
                  </label>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="col-md-12 mt-2" v-if="creditorId == 0">
              <label class="generalLabel" style="text-decoration: underline"
                >Notes:</label
              >
              <textarea
                v-model="paymentChangeData.Notes"
                placeholder="Notes here..."
                class="form-control textareaInput generalTextBox"
                rows="4"
              ></textarea>
            </div>
            <div class="col-md-12 mt-3" v-if="creditorId == 0">
              <label class="generalLabel">Emergency 2 business day</label>
              <input
                type="checkbox"
                id="feesTakenCBoxPopUp"
                v-model="paymentChangeData.IsEmergency"
                class="checkbox-input generalCheckBox mr-2"
                style="
                  float: left;
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
            <div class="col-md-12 text-right mt-2">
              <button
                type="button"
                class="btn btn-danger waves-effect waves-light ml-2"
                @click="openCashFlowPanel"
                v-if="isCashFlowNegative"
              >
                Negative Cash Flow!
              </button>
            </div>
            <div
              v-if="creditorId == 0"
              class="col-md-12"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <button
                @click="submitPaymentChanges()"
                type="button"
                class="btn btn-success waves-effect waves-light ml-2"
                :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
                :disabled="isLoading"
              >
                {{ checkAuth(12411) ? "Request and Approve" : "Submit" }}
              </button>
            </div>

            <div
              v-if="creditorId > 0"
              class="col-md-12 mt-3"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <button
                @click="addPayments()"
                type="button"
                class="btn btn-success waves-effect waves-light ml-2"
                :disabled="isLoading"
              >
                Add Payments
              </button>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import { DatePicker } from "v-calendar";
import CustomNumericInput from "@/components/CustomNumericInput";
const PAYMENT_TYPES = {
  ACH: 4,
  ManualCheck: 5,
  CheckByPhone: 6,
  SecondDayCheck: 7,
  OverniteCheck: 8,
  WireTransfer: 9,
  GlobalDirctPay: 10,
};
export default {
  name: "PaymentChangesPopUp",
  components: { DatePicker, CustomNumericInput },
  props: {
    customerId: {
      Type: Number,
      default: 0,
    },
    customerOfficeId: {
      Type: Number,
      default: 0,
    },
    policyGroupId: {
      Type: Number,
      default: 0,
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
    paymentObj: {
      Type: Object,
      default: null,
    },
    feesTypesList: {
      Type: Array,
      default: [],
    },
    creditorId: {
      Type: Number,
      default: () => 0,
    },
    previousPayment: {
      Type: Object,
      default: null,
    },
    allPaymentList: {
      Type: Array,
      default: [],
    },
    cashFlowList: {
      Type: Array,
      default: [],
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      changeDetails:[],
      isCashFlowNegative: false,
      schedules: [
        { Id: 1, Value: "Monthly" },
        { Id: 2, Value: "Twice a Month" },
        { Id: 3, Value: "Bi-weekly" },
        { Id: 4, Value: "Weekly" },
      ],
      twiceAmonthDay: 0,
      isLoading: false,
      isMaxLimitReachedAdress: false,
      isMaxLimitReachedAdress2: false,
      isMaxLimitReachedCity: false,
      isEmpty: false,
      isPayeeSelected: false,
      payment: {
        PayeeId: 0,
        SettlementId: 0,
        Payee: "",
        EffectiveDate: "",
        AccountNumber: "",
        PaymentType: null,
        PaymentId: null,
        PaymentAmount: 0,
        FeeAmount: 0,
        Notes: "",
        PaymentCount: 1,
        ScheduleType: 1,
      },
      creditorList: [],
      paymentTypes: [],
      paymentChangeData: {
        Notes: "",
        IsEmergency: false,
        ApplicableFees: [],
        CreditorId: 0,
        AcctNumber: "",
        GlobalMemo1: "",
        GlobalMemo2: "",
        GlobalMemo3: "",
      },
      extraFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      tabs: [
        {
          id: 1,
          name: "payee",
          label: "Payee",
          iconName: "",
          active: false,
          visible: false,
        },
        {
          id: 2,
          name: "payeeBank",
          label: "Bank",
          iconName: "",
          active: false,
          visible: false,
        },
        {
          id: 3,
          name: "payeeAddress",
          label: "Address",
          iconName: "",
          active: false,
          visible: false,
        },
        {
          id: 4,
          name: "paymentInfo",
          label: "Payment Info",
          iconName: "",
          active: false,
          visible: false,
        },
      ],
      payeeDetails: null,
      settlementDetails: {
        IsDpg: false,
      },
      payeeList: [],
      payeeFilter: "",
      selectedPayee: { PayeeId: 0, PayeeName: "" },
      newPayee: { PayeeId: "", PayeeName: "" },
      isNewPayeeAdding: false,

      bankTabActive: false,
      selectedBankId: 0,
      selectedBank: {
        BankId: 0,
        AccountNumber: "",
        RoutingNumber: "",
        AccountType: "C",
      },
      bankEditMenuOpen: false,

      addressTabActive: false,
      selectedAddressId: 0,
      selectedAddress: {
        AddressId: 0,
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Email: "",
        Phone: "",
      },
      addressEditMenuOpen: false,
      paymentInfoTabActive: false,
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
  }),
  async mounted() {
    this.settlementDetails.IsDpg = this.isDpg;
    await this.$store.dispatch(globalTypes.GET_STATES);
    await this.getPaymentTypes();

    if (this.paymentObj != null) {
      this.payment = deepCopy(this.paymentObj);
      if (this.payment.PaymentType) {
        this.payment.PaymentType = this.getPaymentTypeKey(
          this.payment.PaymentType
        );
      }

      this.selectedPayee.PayeeId = this.payment.PayeeId;
      this.selectedPayee.PayeeName = this.payment.Payee;
      this.isPayeeSelected = this.payment.PayeeId > 0 ? true : false;
      await this.getPayeeDetails();
    } else {
      await this.getSettledCreditors();
    }

    if (this.previousPayment != null) {
      this.payment.PaymentType = this.getPaymentTypeKey(
        this.previousPayment.PaymentType
      );
      this.payment.Payee = this.previousPayment.Payee;
      this.selectedPayee.PayeeId = this.previousPayment.PayeeId;
      this.selectedPayee.PayeeName = this.previousPayment.Payee;

      this.tabs[3].active = true;
      this.paymentInfoTabActive = true;
    }
  },
  methods: {
    openCashFlowPanel() {
      this.calculateCashFlowForPayments();
      this.$emit("openCashFlowPanel");
    },
    calculateCashFlowForPayments() {

      if (this.checkAuth(956782) && JSON.stringify(this.paymentObj) != JSON.stringify(this.payment)) {
        if (this.cashFlowList && this.cashFlowList.length > 0) {
          let list = deepCopy(this.cashFlowList);

            if(this.payment.PaymentId > 0)
           {
            // update payment
            let paymentAndFees = this.cashFlowList.filter(
              (x) => parseInt(x.ReferenceId) == parseInt(this.paymentObj.PaymentId)
            );
            
            if(paymentAndFees && paymentAndFees.length> 0)
            {
                let tType ="",tDesc="",tDate="",tMemo="";
                for(let i=0;i<paymentAndFees.length;i++)
                {
                  let deletedIndx = list.findIndex(x=> x.Id== paymentAndFees[i].Id);
                  if(deletedIndx > -1)
                  {
                    list[deletedIndx].IsDeletedPay=true;
                  }
                  if(i== 0)
                  {
                    tType=paymentAndFees[i].TransactionType;
                    tDesc=paymentAndFees[i].TransactionTypeDesc;
                    tDate=paymentAndFees[i].Date;
                    tMemo =paymentAndFees[i].Memo;
                  }
                }

                let paymentDate = this.paymentObj.EffectiveDate == this.payment.EffectiveDate
                    ? tDate
                    : this.payment.EffectiveDate;
   
              list.push({
                Id: "X",
                ReferenceId: parseInt(this.payment.PaymentId),
                IsPay: true,
                TransactionType:   tType,
                TransactionTypeDesc: tDesc,
                Date:paymentDate,
                Amount: parseFloat(this.payment.PaymentAmount),
                Memo: tMemo,
                Balance: 0,
              });
              let transactionFee = this.getPaymentTypeTransactionFee(this.payment.PaymentType);
              if(transactionFee > 0)
              {
                let paymentTypeName = this.getPaymentTypeValue(this.payment.PaymentType);
                let firstCharOfPaymentType = "";
                if(paymentTypeName != "" && paymentTypeName.length> 0)
                {
                   firstCharOfPaymentType=paymentTypeName.substring(0,1);
                }
                list.push({
                Id: "X",
                ReferenceId: parseInt(this.payment.PaymentId),
                IsPay: true,
                TransactionType: "P"+ firstCharOfPaymentType.toUpperCase(),
                TransactionTypeDesc: "Payment - " + paymentTypeName + " Fee",
                Date:paymentDate,
                Amount: transactionFee,
                Memo: paymentTypeName + " Payment Fee",
                Balance: 0,
              });
              }
                
            }

            // if (indx > -1) {
           
            // }
          }
          else{
            // add payment
            list.push({
                Id: "X",
                ReferenceId: 0,
                IsPay: true,
                TransactionType: "A",
                TransactionTypeDesc: this.getPaymentTypeValue(this.payment.PaymentType),
                Date: this.payment.EffectiveDate,
                Amount: parseFloat(this.payment.PaymentAmount),
                Memo: this.getCreditorName(this.paymentChangeData.CreditorId),
                Balance: 0,
              });
              let transactionFee = this.getPaymentTypeTransactionFee(this.payment.PaymentType);
              if(transactionFee > 0)
              {
                let paymentTypeName = this.getPaymentTypeValue(this.payment.PaymentType);
                let firstCharOfPaymentType = "";
                if(paymentTypeName != "" && paymentTypeName.length> 0)
                {
                   firstCharOfPaymentType=paymentTypeName.substring(0,1);
                }
                list.push({
                Id: "X",
                ReferenceId: parseInt(this.payment.PaymentId),
                IsPay: true,
                TransactionType: "P"+ firstCharOfPaymentType.toUpperCase(),
                TransactionTypeDesc: "Payment - " + paymentTypeName + " Fee",
                Date: this.payment.EffectiveDate,
                Amount: transactionFee,
                Memo: paymentTypeName + " Payment Fee",
                Balance: 0,
              });
              }
              
          }



        
          let fees = this.paymentChangeData.ApplicableFees;
          if (fees && fees.length > 0) {
            for (let item of fees) {
              let feeAmount = parseFloat(item.FeeAmount);
              let feeDate = new Date(item.FeeDate);
              list.push({
                Id: "X",
                IsPay: true,
                TransactionType: item.GlobalFeeType,
                TransactionTypeDesc: this.getFeeTypeDescription(
                  item.GlobalFeeType
                ),
                Date: feeDate.toString("MM/dd/yyyy"),
                Amount: feeAmount,
                Memo: this.getFeeTypeDescription(
                  item.GlobalFeeType
                ),
                Balance: 0,
              });
            }
          }
          this.$emit("calculateCashFlowForPaymentChanges", list);
          this.isCashFlowNegative = this.checkCashFlowNegative();
        }
      }
    },
    getFeeTypeDescription(value){
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].Description;
        }
      }
      return rtn;
    },
    checkCashFlowNegative() {
      let rtn = false;
      if(this.$parent){
      rtn = this.$parent.checkCashFlowNegativeForPopups();
      }
      return rtn;
    },
    updateTwiceAMonthDay(value) {
      this.twiceAmonthDay = value;
    },
    getPaymentObj() {
      return {
        PaymentId: null,
        PayeeId: this.previousPayment
          ? this.previousPayment.PayeeId
          : this.selectedPayee.PayeeId,
        CreditorId: this.paymentChangeData.CreditorId,
        EffectiveDate: null,
        Payee: this.previousPayment
          ? this.previousPayment.Payee
          : this.selectedPayee.PayeeName,
        AddressId: this.previousPayment
          ? this.previousPayment.AddressId
          : this.selectedAddressId,
        BankId: this.previousPayment
          ? this.previousPayment.BankId
          : this.selectedBankId,
        ContactId: 0,
        SettlementId: this.previousPayment
          ? this.previousPayment.SettlementId
          : this.payment.SettlementId,
        AccountNumber: this.payment.AccountNumber,
        PaymentType: this.previousPayment
          ? this.previousPayment.PaymentType
          : this.getPaymentTypeValue(this.payment.PaymentType),
        PaymentTypeId:this.payment.PaymentType,
        PaymentAmount: 0,
        FeeAmount: 0,
        isEditing: true,
        isDeleting: false,
        NewDate: this.formatShortDate(this.payment.EffectiveDate),
        NewPaymentAmount: parseFloat(this.payment.PaymentAmount),
        NewFeeAmount: parseFloat(this.payment.FeeAmount),
        Memo1: this.paymentChangeData.GlobalMemo1,
        Memo2: this.paymentChangeData.GlobalMemo2,
        Memo3: this.paymentChangeData.GlobalMemo3,
      };
    },
    addPayments() {
      if (this.payment.EffectiveDate == "") {
        this.$swal("Warning!", "Start Date is required", "warning");
        return;
      }

      let feeAmount = parseFloat(this.payment.FeeAmount);

      let paymentAmount =
        this.payment.PaymentAmount != ""
          ? parseFloat(this.payment.PaymentAmount)
          : 0;
      if (paymentAmount == 0 && feeAmount == 0) {
        this.$swal(
          "Warning!",
          "Payment Amount or Fee Amount required",
          "warning"
        );
        return;
      }

      let paymentCount = parseInt(this.payment.PaymentCount);
      if (paymentCount < 1) {
        this.$swal("Warning!", "Payment Count is required", "warning");
        return;
      }

      if (this.payment.ScheduleType == 2) {
        if (this.twiceAmonthDay == 0) {
          this.$swal(
            "Warning!",
            "Day is required for this schedule type",
            "warning"
          );
          return;
        } else if (
          this.twiceAmonthDay == new Date(this.payment.EffectiveDate).getDate()
        ) {
          this.$swal(
            "Warning!",
            "Twice a month day can not be same with start date day.",
            "warning"
          );
          return;
        }
      }

      this.addedList = [];

      for (let i = 0; i < this.payment.PaymentCount; i++) {
        let item = this.getPaymentObj();
        if (i > 0) {
          if (this.payment.ScheduleType == 1) {
            item.NewDate = this.formatShortDate(
              this.addMonthsFix(new Date(this.payment.EffectiveDate), i)
            );
          } else if (this.payment.ScheduleType == 2) {
            let addMonths = Math.floor(i / 2);
            let startDate = new Date(this.payment.EffectiveDate);
            if (i % 2 == 0) {
              item.NewDate = this.formatShortDate(
                this.addMonthsFix(startDate, addMonths)
              );
            } else {
              let startDay = startDate.getDate();
              let newDate = this.addMonthsFix(startDate, addMonths);
              let month = newDate.getMonth();
              let year = newDate.getFullYear();
              if (this.twiceAmonthDay > 0) {
                let maxDay = this.getMaxDayofMonth(new Date(year, month, 1));
                let monthsToAdd = startDay > this.twiceAmonthDay ? 1 : 0;
                item.NewDate = new Date(
                  year,
                  month + monthsToAdd,
                  this.twiceAmonthDay > maxDay ? maxDay : this.twiceAmonthDay
                );
              }
            }
          } else if (this.payment.ScheduleType == 3) {
            item.NewDate = this.formatShortDate(
              this.addDays(new Date(this.payment.EffectiveDate), i * 14)
            );
          } else if (this.payment.ScheduleType == 4) {
            item.NewDate = this.formatShortDate(
              this.addDays(new Date(this.payment.EffectiveDate), i * 7)
            );
          }
          item.PaymentId = -1 * i - 1;
          this.addedList.unshift(item);
        } else {
          item.PaymentId = -1;
          item.NewDate = this.formatShortDate(
            new Date(this.payment.EffectiveDate)
          );
          this.addedList.unshift(item);
        }
      }

      if (this.addedList.length > 0) {
        this.$emit("addBulkPayments", this.addedList);
      } else {
        this.$swal("Warning!", "No payments to add.", "warning");
      }
    },
    checkLength() {
      if (this.selectedAddress.Address1.length > 50) {
        this.selectedAddress.Address1 = this.selectedAddress.Address1.slice(
          0,
          50
        );
        this.isMaxLimitReachedAdress = true;
      } else {
        this.isMaxLimitReachedAdress = false;
      }

      if (this.selectedAddress.Address2.length > 50) {
        this.selectedAddress.Address2 = this.selectedAddress.Address2.slice(
          0,
          50
        );
        this.isMaxLimitReachedAdress2 = true;
      } else {
        this.isMaxLimitReachedAdress2 = false;
      }

      if (this.selectedAddress.City.length > 50) {
        this.selectedAddress.City = this.selectedAddress.City.slice(0, 50);
        this.isMaxLimitReachedCity = true;
      } else {
        this.isMaxLimitReachedCity = false;
      }
    },

    creditorChange(creditorId) {
      if (creditorId > 0 && this.creditorList && this.creditorList.length > 0) {
        let indx = this.creditorList.findIndex((x) => x.Id == creditorId);
        if (indx > -1) {
          this.payment.AccountNumber = this.creditorList[indx].AccountNumber;
        }
      }
    },
    getCreditorName(creditorId) {
      let rtn = "";
      if (creditorId > 0 && this.creditorList && this.creditorList.length > 0) {
        let indx = this.creditorList.findIndex((x) => x.Id == creditorId);
        if (indx > -1) {
          rtn= this.creditorList[indx].Name;
        }
      }
      return rtn;
    },
    async getSettledCreditors() {
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        globalTypes.GET_CUSTOMER_SETTLED_CREDITORS,
        { CustomerId: this.customerId }
      );
      this.isLoading = false;
      if (result && result.Data) {
        this.creditorList = deepCopy(result.Data);
        if (this.creditorId > 0) {
          this.paymentChangeData.CreditorId = this.creditorId;
          this.creditorChange(this.creditorId);
        }
      }
    },
    async getPayeeDetails() {
      let err, result;

      [err, result] = await this.$store.dispatch(types.PAYMENT_GET_DETAILS, {
        CustomerId: this.customerId,
        CreditorId: this.paymentChangeData.CreditorId,
        OfficeId: this.customerOfficeId,
        PayeeId: this.selectedPayee.PayeeId,
        IsDirectPay: this.payment.PaymentType == 10,
      });

      if (result) {
        if (result.Data && result.Data.payeeInfo) {
          this.payeeDetails = result.Data.payeeInfo;
        }

        this.checkTabAuth();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async payeeNameEnter(event) {
      if (event.keyCode == 13) {
        await this.searchPayee();
      }
    },
    async searchPayee() {
      let officeId = this.customerOfficeId;
      let payeeName = this.payeeFilter;
      if (officeId > 0 && payeeName.trim().length > 0) {
        let err, result;
        this.isLoading = true;
        [err, result] = await this.$store.dispatch(types.PAYMENT_PAYEE_SEARCH, {
          OfficeId: officeId,
          CustomerId: this.customerId,
          IsDirectPay: this.payment.PaymentType == 10 ? true : false,
          Name: payeeName,
        });
        this.isLoading = false;

        if (result) {
          this.isNewPayeeAdding = false;
          if (result.Data && result.Data.length > 0) {
            this.payeeList = result.Data;
          } else {
            this.$swal(
              "Result",
              "No records found for " + payeeName,
              "warning"
            );
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        this.$swal(
          "Warning!",
          "OfficeId and Payee Name parameters is required.",
          "warning"
        );
      }
    },
    async showNewPayeePanel() {
      this.payeeList = [];
      this.isNewPayeeAdding = true;
    },

    async selectPayee(item) {
      this.selectedPayee.PayeeId = item.PayeeId;
      this.selectedPayee.PayeeName = item.PayeeName;
      this.payment.PayeeId = item.PayeeId;
      this.payment.Payee = item.PayeeName;
      this.selectedBankId = 0;
      this.selectedContactId = 0;
      this.selectedAddressId = 0;
      this.payeeList = [];
      this.isLoading = true;
      await this.getPayeeDetails();
      this.isLoading = false;
      this.checkTabAuth();
    },
    async addNewPayee() {
      let payeeId = this.newPayee.PayeeId;
      let payeeName = this.newPayee.PayeeName;
      let officeId = this.customerOfficeId;
      if (payeeId.trim().length > 0 && payeeName.trim().length > 0) {
        let err, result;

        [err, result] = await this.$store.dispatch(types.PAYMENT_NEW_PAYEE, {
          CustomerId: this.customerId,
          OfficeId: officeId,
          PayeeId: payeeId,
          Name: payeeName,
        });
        if (result) {
          this.selectedPayee.PayeeId = result.Data.PayeeId;
          this.selectedPayee.PayeeName = result.Data.PayeeName;
          this.isNewPayeeAdding = false;
          this.payeeFilter = "";
          await this.getPayeeDetails();
          this.checkTabAuth();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    cancelNewPayee() {
      this.newPayee.PayeeId = "";
      this.newPayee.PayeeName = "";
      this.isNewPayeeAdding = false;
    },
    zipChanged(event) {
      if (event.target.value) {
        this.selectedAddress.Zip = event.target.value;
      }
    },
    paymentTypeChanged() {
      this.tabs[0].active = false;
      this.tabs[1].active = false;
      this.tabs[2].active = false;
      this.tabs[3].active = false;
      this.selectedBankId = 0;
      this.selectedAddressId = 0;
      this.selectedContactId = 0;
      this.bankTabActive = false;
      this.contactTabActive = false;
      this.addressTabActive = false;
      this.paymentInfoTabActive = false;
      this.checkTabAuth();
      this.tabs[1].active = false;
      this.tabs[2].active = false;
      this.tabs[3].active = false;
      this.tabs[0].active = true;
      this.handleTabChange(this.tabs[0].name);
    },
    handleTabChange(tabName) {
     
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name === tabName ? true : false;
        return item;
      });
      this.tabs = [...temp];

      if(tabName=="paymentInfo")
      {
        
        this.calculateCashFlowForPayments();
      }
    },
    getPaymentTypeKey(value) {
      let rtn = "";
      if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.Name == value);
        if (index > -1) {
          rtn = this.paymentTypes[index].Id;
        }
      }
      return rtn;
    },
    getPaymentTypeValue(value) {
      let rtn = "";
      if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.Id == value);
        if (index > -1) {
          rtn = this.paymentTypes[index].Name;
        }
      }
      return rtn;
    },
    getPaymentTypeTransactionFee(value) {
      let rtn = 0;
      if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.Id == value);
        if (index > -1) {
          rtn = parseFloat(this.paymentTypes[index].TransactionFee);
        }
      }
      return rtn;
    },
    async getPaymentTypes() {
      if (this.paymentTypes.length > 0) return;

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_PAYMENT_CHANGES_PAYMENT_TYPES_WITH_FEES,
        {PolicyGroupId: this.policyGroupId}
      );
      if (result && result.Data) {
        this.paymentTypes = deepCopy(result.Data);
      }
    },
    closePanel() {
      this.$emit("closePaymentChangesPopup");
    },
    addExtraFee() {
      if (
        this.extraFees.GlobalFeeType != null &&
        this.extraFees.FeeAmount > 0 &&
        this.extraFees.FeeDate != ""
      ) {
        let item = deepCopy(this.extraFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.paymentChangeData.ApplicableFees.push(item);
        this.extraFees.GlobalFeeType = null;
        this.extraFees.FeeAmount = 0;
        this.extraFees.FeeDate = "";
        this.calculateCashFlowForPayments();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required.",
          "warning"
        );
      }
    },
    deleteExtraFee(indx) {
      if (indx > -1) {
        this.paymentChangeData.ApplicableFees.splice(indx, 1);
        this.calculateCashFlowForPayments();
      }
    },
    async submitPaymentChanges() {
      this.changeDetails = [
        {
          EffectiveDate: this.paymentObj
            ? this.formatShortDate(this.paymentObj.EffectiveDate)
            : null,
          NewDate: this.formatShortDate(this.payment.EffectiveDate),
          PaymentId: this.payment.PaymentId,
          PaymentAmount: parseFloat(this.payment.PaymentAmount),
          FeeAmount: parseFloat(this.payment.FeeAmount),
          PaymentType: this.getPaymentTypeValue(this.payment.PaymentType),
          PaymentTypeId: this.payment.PaymentType,
          PayeeAddressId: this.selectedAddressId,
          PayeeBankId: this.selectedBankId,
          PayeeContactId: 0,
          PayeeName: this.selectedPayee.PayeeName,
          PayeeId: this.selectedPayee.PayeeId,
          PayeeAccountNumber: this.payment.AccountNumber,
          Memo1: this.paymentChangeData.GlobalMemo1,
          Memo2: this.paymentChangeData.GlobalMemo2,
          Memo3: this.paymentChangeData.GlobalMemo3,
          CreditorId: this.paymentChangeData.CreditorId,
          SettlementId: this.payment.SettlementId,
        },
      ];

      if (this.customerId == 0) {
        this.$swal("Warning!", "Invalid customer id", "warning");
        return;
      }

      if (this.paymentChangeData.Notes.trim().length == 0) {
        this.$swal("Warning!", "Note field is required.", "warning");
        return;
      }

      //remaining fee validation

      let targetCreditorId = this.payment.CreditorId;
      if (targetCreditorId > 0) {
        let totalSettlementFeeAmount = 0;
        let feesByCreditor = this.allPaymentList
          .filter((x) => x.CreditorId == targetCreditorId)
          .filter((y) => y.PaymentId != this.payment.PaymentId);

        if (feesByCreditor && feesByCreditor.length > 0) {
          for (let f of feesByCreditor) {
            totalSettlementFeeAmount += parseFloat(f.FeeAmount);
          }
          totalSettlementFeeAmount =
            totalSettlementFeeAmount + parseFloat(this.payment.FeeAmount);
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.GET_SETTLEMENT_FEE_INFO,
            {
              CustomerId: this.customerId,
              CreditorId: targetCreditorId,
            }
          );

          if (result && result.Data) {
            let settlementFeeMaxAmount = parseFloat(result.Data.MaxFees);

            let settlementFeeClearedAmount = parseFloat(
              result.Data.ClearedFees
            );

            let remainingFeeAmount = parseFloat(
              settlementFeeMaxAmount - settlementFeeClearedAmount
            );

            // calculated total fee can not be grater than remaining fee from api data.
            if (
              parseFloat(totalSettlementFeeAmount.toFixed(2)) >
              parseFloat(remainingFeeAmount.toFixed(2))
            ) {
              this.$swal(
                "Warning!",
                "The fee amount must not exceed the scheduled settlement fee. Remaining fee amount: " +
                  this.selectedPayee.PayeeName +
                  " - " +
                  this.formatMoney(remainingFeeAmount),
                "warning"
              );
              return;
            }
          }
        }
      }

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            this.isLoading = true;
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.CREATE_PAYMENT_CHANGE,
              {
                CustomerId: this.customerId,
                Notes: this.paymentChangeData.Notes,
                IsEmergency: this.paymentChangeData.IsEmergency,
                ChangeDetails: this.changeDetails,
                ApplicableFees: this.paymentChangeData.ApplicableFees,
                IsNegativeCashFlow:this.isCashFlowNegative
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Payment changes requested.";
              this.$swal("Success!", successMessage, "success");
              this.paymentChangeData.CreditorId = 0;
              this.paymentChangeData.Notes = "";
              this.paymentChangeData.IsEmergency = false;
              this.Notes = "";
              this.paymentChangeData.ApplicableFees = [];
              this.$emit("submitPaymentChanges", this.checkAuth(12411));
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoading = false;
        });
    },
    checkTabAuth() {
      this.bankTabActive = false;
      this.contactTabActive = false;
      this.addressTabActive = false;
      this.paymentInfoTabActive = false;

      this.tabs[0].visible = false;
      this.tabs[1].visible = false;
      this.tabs[2].visible = false;
      this.tabs[3].visible = false;
      this.tabs[0].active = false;
      this.tabs[1].active = false;
      this.tabs[2].active = false;
      this.tabs[3].active = false;

      if (this.settlementDetails.IsDpg) {
        if (
          this.payeeDetails &&
          this.payeeDetails.Banks &&
          this.payeeDetails.Banks.length > 0
        ) {
          this.bankTabActive =
            this.payeeDetails.Banks.length > 0
              ? this.payment.PayeeId > 0
              : true;
          this.tabs[0].visible = true;
          this.tabs[1].visible = this.payeeDetails.Banks.length > 0;
          this.addressTabActive =
            this.payeeDetails.Banks.length > 0 ? this.selectedBankId > 0 : true;
          if (this.addressTabActive == true) {
            this.handleTabChange(this.tabs[2].name);
          }
          this.tabs[2].visible = true;
        } else {
          this.tabs[0].visible = true;
          this.bankTabActive = false;
          this.tabs[1].visible = false;
          this.addressTabActive = true;
          this.tabs[2].visible = true;
          if (this.addressTabActive == true) {
            this.handleTabChange(this.tabs[2].name);
          }
        }

        this.paymentInfoTabActive = this.selectedAddressId > 0;

        this.tabs[3].visible = true;
      } else {
        if (this.payment.PaymentType == PAYMENT_TYPES.ACH) {
          this.bankTabActive = true;
          this.paymentInfoTabActive = this.selectedBankId > 0;
          this.tabs[0].visible = true;
          this.tabs[1].visible = true;
          this.tabs[1].active = true;
          this.tabs[3].visible = true;
        } else if (this.payment.PaymentType == PAYMENT_TYPES.ManualCheck) {
          this.addressTabActive = true;
          this.paymentInfoTabActive = this.selectedAddressId > 0;
          this.tabs[0].visible = true;
          this.tabs[2].visible = true;
          this.tabs[2].active = true;
          this.tabs[3].visible = true;
        } else if (this.payment.PaymentType == PAYMENT_TYPES.CheckByPhone) {
          this.tabs[0].visible = true;
          this.paymentInfoTabActive = true;
          this.tabs[3].visible = true;
          this.tabs[3].active = true;
        } else if (this.payment.PaymentType == PAYMENT_TYPES.SecondDayCheck) {
          this.tabs[0].visible = true;
          this.addressTabActive = true;
          this.paymentInfoTabActive = this.selectedAddressId > 0;
          this.tabs[2].visible = true;
          this.tabs[2].active = true;
          this.tabs[3].visible = true;
        } else if (this.payment.PaymentType == PAYMENT_TYPES.OverniteCheck) {
          this.addressTabActive = true;
          this.paymentInfoTabActive = this.selectedAddressId > 0;
          this.tabs[0].visible = true;
          this.tabs[2].visible = true;
          this.tabs[2].active = true;
          this.tabs[3].visible = true;
        } else if (this.payment.PaymentType == PAYMENT_TYPES.WireTransfer) {
          this.bankTabActive = true;
          this.addressTabActive = this.selectedBankId > 0;
          this.paymentInfoTabActive = this.selectedAddressId > 0;
          this.tabs[0].visible = true;
          this.tabs[1].visible = true;
          this.tabs[1].active = true;
          this.tabs[2].visible = true;
          this.tabs[3].visible = true;
        } else if (this.payment.PaymentType == PAYMENT_TYPES.GlobalDirctPay) {
          this.paymentInfoTabActive = true;
          this.tabs[0].visible = true;
          this.tabs[3].visible = true;
          this.tabs[3].active = true;
        }
      }
    },
    async selectBank(item) {
      this.selectedBankId = item.BankId;
      this.checkTabAuth();
    },
    addBank() {
      this.selectedBank = {
        BankId: 0,
        RoutingNumber: "",
        AccountNumber: "",
        AccountType: "C",
      };
      this.bankEditMenuOpen = true;
    },
    editBank(item) {
      this.selectedBank.BankId = item.BankId;
      this.selectedBank.AccountNumber = item.AccountNumber;
      this.selectedBank.RoutingNumber = item.RoutingNumber;
      this.selectedBank.AccountType = item.AccountType;
      this.bankEditMenuOpen = true;
    },
    cancelBank() {
      this.bankEditMenuOpen = false;
    },
    async saveBank() {
      if (
        this.selectedBank.AccountNumber != "" &&
        this.selectedBank.RoutingNumber != ""
      ) {
        let err, result;

        let payload = {
          CustomerId: this.customerId,
          OfficeId: this.customerOfficeId,
          PayeeId: this.payment.PayeeId,
          RoutingNumber: this.selectedBank.RoutingNumber,
          AccountNumber: this.selectedBank.AccountNumber,
          AccountType: this.selectedBank.AccountType,
        };

        let payloadType = "";
        if (this.selectedBank.BankId != 0) {
          payload.BankId = this.selectedBank.BankId;
          payloadType = types.UPDATE_PAYEE_BANK;
        } else {
          payloadType = types.ADD_PAYEE_BANK;
        }
        [err, result] = await this.$store.dispatch(payloadType, payload);

        if (result) {
          this.$swal("Success", "", "success");
          this.bankEditMenuOpen = false;
          await this.getPayeeDetails();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    deleteBank(id) {
      this.$swal({
        title: "CONFIRM",
        text: "Are you sure to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          let err, result;
          [err, result] = await this.$store.dispatch(types.REMOVE_PAYEE_BANK, {
            CustomerId: this.customerId,
            OfficeId: this.customerOfficeId,
            PayeeId: this.payment.PayeeId,
            BankId: id,
          });

          if (result) {
            this.$swal("Success", "", "success");
            if (this.selectedBankId == id) {
              this.selectedBankId = 0;
              this.handleTabChange();
            }
            this.bankEditMenuOpen = false;
            await this.getPayeeDetails();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },

    selectAddress(item) {
      this.selectedAddressId = item.AddressId;
      this.checkTabAuth();
    },
    addAddress() {
      this.selectedAddress = {
        AddressId: 0,
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Phone: "",
        Email: "",
      };
      this.addressEditMenuOpen = true;
    },
    async editAddress(item) {
      this.selectedAddress.AddressId = item.AddressId;
      this.selectedAddress.Address1 = item.Address1;
      this.selectedAddress.Address2 = item.Address2;
      this.selectedAddress.City = item.City;
      this.selectedAddress.State = item.State;
      this.selectedAddress.Zip = item.Zip;
      this.addressEditMenuOpen = true;
    },
    cancelAddress() {
      this.addressEditMenuOpen = false;
    },
    async saveAddress() {
      let requestValid = false;

      if (this.settlementDetails.IsDpg) {
        requestValid =
          this.selectedAddress.Address1 != "" &&
          this.selectedAddress.State != "" &&
          this.selectedAddress.Zip != "" &&
          this.selectedAddress.Phone != "" &&
          this.selectedAddress.Email != "";
      } else {
        requestValid =
          this.selectedAddress.Address1 != "" &&
          this.selectedAddress.State != "" &&
          this.selectedAddress.Zip != "" &&
          this.selectedAddress.City != "";
      }

      if (requestValid) {
        let err, result;

        let payload = {
          CustomerId: this.customerId,
          OfficeId: this.customerOfficeId,
          PayeeId: this.payment.PayeeId,
          Address1: this.selectedAddress.Address1,
          Address2: this.selectedAddress.Address2,
          City: this.selectedAddress.City,
          Zip: this.selectedAddress.Zip,
          State: this.selectedAddress.State,
          Email: this.selectedAddress.Email,
          Phone: this.selectedAddress.Phone,
        };

        let payloadType = "";
        if (this.selectedAddress.AddressId != 0) {
          payload.AddressId = this.selectedAddress.AddressId;
          payloadType = types.UPDATE_PAYEE_ADDRESS;
        } else {
          payloadType = types.ADD_PAYEE_ADDRESS;
        }
        [err, result] = await this.$store.dispatch(payloadType, payload);

        if (result) {
          this.$swal("Success", "", "success");
          this.addressEditMenuOpen = false;
          await this.getPayeeDetails();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        let validationMessage = this.settlementDetails.IsDpg
          ? "Address1, State, Zip, Phone and Email required."
          : "Address1, State, City, Zip required.";
        this.$swal("Warning!", validationMessage, "warning");
      }
    },
    deleteAddress(id) {
      this.$swal({
        title: "CONFIRM",
        text: "Are you sure to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.REMOVE_PAYEE_ADDRESS,
            {
              CustomerId: this.customerId,
              OfficeId: this.customerOfficeId,
              PayeeId: this.payment.PayeeId,
              AddressId: id,
            }
          );

          if (result) {
            this.$swal("Success", "", "success");
            this.addressEditMenuOpen = false;
            if (this.selectedAddressId == id) {
              this.selectedAddressId = 0;
              this.handleTabChange();
            }
            await this.getPayeeDetails();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>
