<template>
  <div id="draftChangesSection">
    <div
      v-show="isCashFlowPanelVisible"
      class="col-12"
      style="width: 100%; height: 100%"
    >
      <div class="cashflowMoveableDraft">
        <div class="col-md-12 d-flex text-left">
          <label class="generalLabel text-left w-100"
            >Cash Flow <span style="color: red">
              (You can drag and drop this panel)</span></label
          >
         
            <div
            class="closeButton2"
            v-on:click.prevent.stop="closeCashFlowPanel"
          >
            <i class="fas fa-window-close"></i>
          </div>
        </div>
        <div class="col-md-12 noPadding">
          <div class="d-flex ml-2">
            <div style="display: flex">
              <input
                autocomplete="off"
                type="checkbox"
                v-model="calculateWithOngoingSettlements"
                @change="ongoingSettlementChange"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
          <div>
              <label class="generalLabel pr-2">Include Ongoing Settlements</label>
            </div>

            
        
        </div>
          <perfect-scrollbar style="max-height: 664px; position: relative">
            <div
              class="datagrid-table"
              style="background-color: #fafafa"
              v-if="!tableLoading"
            >
              <o-table :data="cashFlowList">
                <o-table-column
                  field="TransactionType"
                  label="T.Type"
                  v-slot="props"
                >
                  <span
                    v-bind:class="{
                      textGreen:
                        props.row.IsCurrentTask && props.row.Balance >= 0,
                      textRed2:
                        props.row.IsCurrentTask && props.row.Balance < 0,
                    }"
                  >
                    {{ props.row.TransactionType }}
                  </span>
                </o-table-column>
                <o-table-column field="Date" label="Date" v-slot="props">
                  <span
                    v-bind:class="{
                      textGreen:
                        props.row.IsCurrentTask && props.row.Balance >= 0,
                      textRed2:
                        props.row.IsCurrentTask && props.row.Balance < 0,
                    }"
                  >
                    {{ formatShortDate(props.row.Date) }}
                  </span>
                </o-table-column>

                <o-table-column field="Balance" label="Net Cash">
                  <template v-slot="props">
                    <div
                      v-bind:class="{
                        textGreen:
                          props.row.IsCurrentTask && props.row.Balance >= 0,
                        textRed: props.row.Balance < 0,
                        textRed2:
                          props.row.IsCurrentTask && props.row.Balance < 0,
                      }"
                    >
                      {{ formatMoney(props.row.Balance) }}
                    </div>
                  </template>
                </o-table-column>
                <o-table-column field="Memo" label="Memo" v-slot="props">
                  <span
                    v-bind:class="{
                      textGreen:
                        props.row.IsCurrentTask && props.row.Balance >= 0,
                      textRed2:
                        props.row.IsCurrentTask && props.row.Balance < 0,
                    }"
                  >
                    {{ props.row.Memo }}
                  </span>
                </o-table-column>
              </o-table>
              <div
                v-if="tableLoading"
                class="loadingAbsoluteBox"
                style="background: #fff"
              >
                <div class="loadingSpinner">
                  <i class="spinner-border text-primary m-2"></i>
                </div>
              </div>
              <section
                v-if="!tableLoading && cashFlowList.length == 0"
                class="section"
                style="text-align: center"
              >
                <div class="content has-text-grey has-text-centered mb-4">
                  <p class="noMargin">
                    <i
                      style="font-size: 24px; color: #939393"
                      class="ri-question-line"
                    ></i>
                  </p>
                  <p class="noMargin">Nothing here.</p>
                </div>
              </section>
            </div>
          </perfect-scrollbar>
        </div>
      </div>

      <Moveable v-bind="moveable" @drag="handleDrag" />
    </div>
  </div>
  <div class="col-md-12">
    <div v-if="isLoadingApproveButton" class="newLoading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <section
      v-if="notAuhtorizedTab"
      class="col-md-12 pt-5"
      style="text-align: center"
    >
      <div class="content has-text-grey has-text-centered mb-4">
        <p class="noMargin">
          <i
            style="font-size: 24px; color: #939393"
            class="far fa-times-circle pb-2"
          ></i>
        </p>
        <p class="noMargin">You are not authorized to access this tab.</p>
      </div>
    </section>
    <div class="form-group row" v-if="!notAuhtorizedTab">
      <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 alignLeft">
        <div
          class="col-md-12"
          v-if="checkAuth(6644) || checkAuth(6645) || checkAuth(6646)"
        >
          <ul
            class="nav nav-tabs"
            style="border-bottom: 2px solid #3bafda !important"
          >
            <li
              v-for="tab in tabs"
              :key="tab.id"
              class="nav-item tab-item"
              v-showIfAuthorized="tab.authorizationId"
            >
              <a
                data-toggle="tab"
                :aria-expanded="tab.active"
                v-on:click.prevent="() => handleTabChange(tab.name)"
                class="tab-link"
                :class="{ active: tab.active }"
              >
                <span class="d-sm-inline-block tab-item-label">
                  {{ tab.label }}
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div v-show="tabs[0].active && checkAuth(6644)" class="form-group row">
          <div class="col-md-12 row">
            <div class="col-md-6">
              <label class="generalLabel">Payment Count:</label>
              <select
                id="slcPaymentCount"
                class="form-control"
                style="width: 150px"
                v-model="skipPaymentData.PaymentCount"
                @change="
                  calculateCashFlowForSkipPayment(
                    skipPaymentData.PaymentCount,
                    skipPaymentData.DraftCount
                  )
                "
              >
              <option value="0">Please Select</option>
                <option value="1">1 Payment</option>
                <option value="2">2 Payments</option>
                <option value="3">3 Payments</option>
                <option value="4">4 Payments</option>
                <option value="5">5 Payments</option>
                <option value="6">6 Payments</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="generalLabel">Draft Count:</label>
              <select
                id="isDividePaymentSelect"
                class="form-control"
                :class="skipPaymentData.IsDividePayments ? '' : 'readonlyInput'"
                style="width: 150px"
                :disabled="!skipPaymentData.IsDividePayments"
                v-model="skipPaymentData.DraftCount"
                @change="
                  calculateCashFlowForSkipPayment(
                    skipPaymentData.PaymentCount,
                    skipPaymentData.DraftCount
                  )
                "
              >
                <option value="0">Please Select</option>
                <option value="1">1 Payment</option>
                <option value="2">2 Payments</option>
                <option value="3">3 Payments</option>
                <option value="4">4 Payments</option>
                <option value="5">5 Payments</option>
                <option value="6">6 Payments</option>
                <option value="7">7 Payments</option>
                <option value="8">8 Payments</option>
                <option value="9">9 Payments</option>
                <option value="10">10 Payments</option>
                <option value="11">11 Payments</option>
                <option value="12">12 Payments</option>
                <option value="13">13 Payments</option>
                <option value="14">14 Payments</option>
                <option value="15">15 Payments</option>
                <option value="16">16 Payments</option>
                <option value="17">17 Payments</option>
              </select>
            </div>
          </div>
          <div class="col-md-12 mt-1">
            <div class="col-md-12">
              <input
                v-model="skipPaymentData.IsDividePayments"
                type="checkbox"
                id="divideOverPaymentCBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="generalLabel"> Divide Over Payments</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-12">
              <textarea
                v-model="skipPaymentData.Notes"
                id="noteTextArea"
                class="form-control textareaInput generalTextBox"
                rows="5"
                placeholder="Notes"
              ></textarea>
            </div>
          </div>
          <div
            v-if="checkAuth(54983) && isDpgCustomer == false"
            class="col-md-12"
            style="display: flex"
          >
            <div class="col-md-12">
              <input
                @click="openSkipPaymentApplicableFees()"
                :checked="isOpenSkipPaymentApplicableFees"
                type="checkbox"
                id="feesTakenCBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="generalLabel ml-2"
                >Fees taken out of skip payment?</label
              >
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="col-md-12 noPadding"
              style="display: flex"
              v-if="
                checkAuth(54983) &&
                isDpgCustomer == false &&
                isOpenSkipPaymentApplicableFees
              "
            >
              <div class="col-md-4">
                <label class="generalLabel">Fee Type </label>
                <select
                  class="form-control"
                  v-model="skipyPaymentFees.GlobalFeeType"
                >
                  <option :value="null">Please Select</option>
                  <option
                    v-for="item in applicableFeeTypes"
                    :value="item.FeeType"
                    :key="item.FeeType"
                  >
                    {{ item.Description }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="serviceFee-sp" class="generalLabel">Amount</label>
                <input
                  v-model="skipyPaymentFees.FeeAmount"
                  class="form-control"
                  id="skipPaymentAmount"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                    allowMinus: false,
                  }"
                  placeholder="$0.00"
                />
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Amount Date</label>
                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="skipyPaymentFees.FeeDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DC-skipPaymentFeeDate"
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="col-md-1" style="padding-top: 28px">
                <button
                  @click="addSkipPaymentFees()"
                  type="button"
                  class="btn btn-success waves-effect waves-light"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <perfect-scrollbar
              style="
                width: 100%;
                max-height: 220px;
                overflow-x: hidden !important;
              "
            >
              <div class="col-md-12 pt-3">
                <label
                  v-for="(item, index) in skipPaymentData.ApplicableFees"
                  :key="index"
                >
                  <div style="display: flex; align-items: stretch">
                    <label class="generalLabel pr-2"
                      >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                        item.GlobalFeeType
                      }}</label></label
                    >
                    <label class="generalLabel pr-2"
                      >Amount:<label class="ml-1" style="color: #6c757d">{{
                        formatMoney(item.FeeAmount)
                      }}</label></label
                    >
                    <label class="generalLabel pr-1"
                      >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                        new Date(item.FeeDate).toLocaleDateString()
                      }}</label></label
                    >

                    <span
                      style="padding: 5px; cursor: pointer; height: 20px"
                      class="badge badge-danger"
                      @click="deleteSkipPaymentFees(index)"
                    >
                      Cancel</span
                    >
                  </div>
                </label>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="col-md-12">
            <div
              class="col-md-12"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <button
                type="button"
                class="btn btn-danger waves-effect waves-light ml-2"
                @click="openCashFlowPanel"
                v-if="isNegativeCashFlow && checkAuth(956782)"
              >
                Negative Cash Flow!
              </button>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="col-md-12"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <input
                v-model="skipPaymentData.IsEmergency"
                type="checkbox"
                id="emergencyCBox1"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="generalLabel mt-1 ml-1">Emergency (2 B.days)</label>
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light ml-2"
                :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
                @click="skipPaymentRequest"
                :disabled="isLoadingApproveButton"
              >
                {{ checkAuth(12411) ? "Request and Approve" : "Request" }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-show="tabs[1].active && checkAuth(6645)"
          class="form-group row pl-1 pr-1"
        >
          <div class="col-md-12 row">
            <div class="col-md-12 noPadding">
              <div class="col-xl-6 col-lg-6 col-md-12">
                <label class="generalLabel">Amount</label>
                <input
                  v-model="extraPaymentData.DraftAmount"
                  class="form-control"
                  id="amountImp"
                  type="text"
                  placeholder="$0.00"
                  @change="calculateCashFlowForExtraPayment()"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                    allowMinus: false,
                  }"
                />
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12">
                <label for="date" class="generalLabel"> Date </label>

                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="extraPaymentData.DraftDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DCdraftDate"
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12">
                <label class="generalLabel">Type</label>
                <select
                  id="typeSBox"
                  class="form-control"
                  style="width: 100% !important"
                  v-model="extraPaymentData.DraftTypeId"
                >
                  <option value="null">Please Select</option>
                  <option
                    v-for="item in extraPaymentDraftTypes"
                    :value="item.DraftTypeId"
                    :key="item.DraftTypeId"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <label class="generalLabel">Reason</label>
              <textarea
                v-model="extraPaymentData.Notes"
                id="reasonTextArea"
                class="form-control textareaInput generalTextBox"
                style="padding: 15px 12px"
                rows="8"
                placeholder="Reason"
              ></textarea>
            </div>
          </div>

          <div
            v-if="checkAuth(54983) && isDpgCustomer == false"
            class="col-md-12"
            style="display: flex"
          >
            <div class="col-md-12">
              <input
                @click="openExtraDrafts()"
                :checked="extraDraftsTab"
                type="checkbox"
                id="feesTakenCBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="generalLabel ml-2"
                >Fees taken out of extra draft?</label
              >
            </div>
          </div>

          <div class="col-md-12" v-show="extraDraftsTab">
            <div class="col-md-12 noPadding" style="display: flex">
              <div class="col-md-4">
                <label for="serviceFee" class="generalLabel">Fee Type</label>
                <select
                  class="form-control"
                  v-model="extraFeesTabData.GlobalFeeType"
                >
                  <option :value="null">Please Select</option>
                  <option
                    v-for="item in applicableFeeTypes"
                    :value="item.FeeType"
                    :key="item.FeeType"
                  >
                    {{ item.Description }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Amount</label>
                <input
                  v-model="extraFeesTabData.FeeAmount"
                  class="form-control"
                  id="amount"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                    allowMinus: false,
                  }"
                  placeholder="$0.00"
                />
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Amount Date</label>
                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="extraFeesTabData.FeeDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DC-feeDate"
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="col-md-1" style="padding-top: 28px">
                <button
                  @click="addExtraDraftFee()"
                  type="button"
                  class="btn btn-success waves-effect waves-light"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <perfect-scrollbar
              style="
                width: 100%;
                max-height: 220px;
                overflow-x: hidden !important;
              "
            >
              <div class="col-md-12 pt-3">
                <label
                  v-for="(item, index) in extraPaymentData.ApplicableFees"
                  :key="index"
                >
                  <div style="display: flex; align-items: stretch">
                    <label class="generalLabel pr-2"
                      >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                        item.GlobalFeeType
                      }}</label></label
                    >
                    <label class="generalLabel pr-2"
                      >Amount:<label class="ml-1" style="color: #6c757d">{{
                        formatMoney(item.FeeAmount)
                      }}</label></label
                    >
                    <label class="generalLabel pr-1"
                      >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                        new Date(item.FeeDate).toLocaleDateString()
                      }}</label></label
                    >

                    <span
                      style="padding: 5px; cursor: pointer; height: 20px"
                      class="badge badge-danger"
                      @click="deleteExtraPaymentData(index)"
                    >
                      Cancel</span
                    >
                  </div>
                </label>
              </div>
            </perfect-scrollbar>
          </div>

          <div class="col-md-12">
            <div class="col-md-12 text-right">
              <button
                type="button"
                class="btn btn-danger waves-effect waves-light ml-2"
                @click="openCashFlowPanel"
                v-if="isNegativeCashFlowForExtraDraft && checkAuth(956782)"
              >
                Negative Cash Flow!
              </button>
            </div>
            <div
              class="col-md-12"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <input
                v-model="extraPaymentData.IsEmergency"
                type="checkbox"
                id="emergencyCBox2"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="generalLabel mt-1 ml-1">Emergency (2 B.days)</label>
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light col-md-3 ml-2"
                :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
                @click="extraPaymentRequest"
                :disabled="isLoadingApproveButton"
              >
                {{ checkAuth(12411) ? "Request and Approve" : "Request" }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-show="tabs[2].active && checkAuth(6646)"
          class="form-group row mt-2 pl-1 pr-1"
        >
          <div class="col-md-12">
            <div class="col-md-12">
              <label class="generalLabel">Comment</label>
              <textarea
                id="noteTextEditor"
                class="form-control textareaInput generalTextBox"
                rows="6"
                v-model="additionalCommentData.Notes"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="col-md-12"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <input
                v-model="additionalCommentData.IsEmergency"
                type="checkbox"
                id="emergencyCBox3"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="generalLabel mt-1 ml-1">Emergency (2 B.days)</label>
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light ml-2"
                :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
                @click="additionalCommentRequest"
                :disabled="isLoadingApproveButton"
              >
                {{ checkAuth(12411) ? "Request and Approve" : "Request" }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
        <div
          class="col-md-12"
          v-if="
            checkAuth(6641) ||
            checkAuth(6642) ||
            checkAuth(6643) ||
            checkAuth(6654)
          "
        >
          <ul
            class="nav nav-tabs"
            style="border-bottom: 2px solid #3bafda !important"
          >
            <li
              v-for="tab in rightTabs"
              :key="tab.id"
              class="nav-item tab-item"
              v-showIfAuthorized="tab.authorizationId"
            >
              <a
                data-toggle="tab"
                :aria-expanded="tab.active"
                v-on:click.prevent="
                  () => handleRightTabChange(tab.id, tab.name, false)
                "
                class="tab-link"
                :class="{ active: tab.active }"
              >
                <span class="d-sm-inline-block tab-item-label">
                  {{ tab.label }}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-12 noPadding">
          <div
            class="row col-md-12 noPadding"
            v-if="rightTabs[0].active && checkAuth(6641)"
          >
            <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openDraftChangePopup"
                type="button"
                class="btn btn-primary col-md-12"
              >
                Request Draft Changes
              </button>
            </div>
            <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openBulkRequestPopUp"
                type="button"
                class="btn btn-success col-md-12"
              >
                Bulk Request
              </button>
            </div>
          </div>
          <div
            v-if="rightTabs[1].active && checkAuth(6642)"
            class="row noPadding col-xl-12 col-lg-12 col-md-12 col-sm-12"
          >
            <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openFeeChangePopup"
                type="button"
                class="btn btn-primary col-md-12"
              >
                Request Fee Changes
              </button>
            </div>
            <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openFeeRequestPopUp"
                type="button"
                class="btn btn-success col-md-12"
              >
                Bulk Fee Request
              </button>
            </div>
          </div>
          <div
            v-if="rightTabs[2].active && checkAuth(6643)"
            class="row noPadding col-xl-12 col-lg-12 col-md-12 col-sm-12"
          >
            <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openPaymentChangesPopup(null)"
                type="button"
                class="btn btn-primary col-md-12"
              >
                Add New Payment
              </button>
            </div>
            <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openPaymentRequestPopUp()"
                type="button"
                class="btn btn-success col-md-12"
              >
                Bulk Payment Request
              </button>
            </div>
          </div>
          <div
            v-if="rightTabs[3].active && checkAuth(6654)"
            class="row noPadding col-xl-12 col-lg-12 col-md-12 col-sm-12"
          >
            <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openChargeChangesPopup()"
                type="button"
                class="btn btn-primary col-md-12"
              >
                Request Charges Changes
              </button>
            </div>
            <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
              <button
                @click="openBulkChargePopup()"
                type="button"
                class="btn btn-success col-md-12"
              >
                Bulk Charges Request
              </button>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="loading">
          <div class="text-center" style="margin-top: 150px">
            <i class="spinner-grow text-info m-2"></i>
          </div>
        </div>
        <perfect-scrollbar
          v-if="!isLoading"
          style="height: 400px; position: relative"
        >
          <div v-show="rightTabs[0].active && checkAuth(6641)">
            <table
              class="datagrid-table creditorTable"
              id="btn-editable-dc"
              style="
                border: none !important;
                background-color: rgb(255, 255, 255);
              "
            >
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th width="30%">Effective Date</th>
                  <th width="30%">Debit Amount</th>
                  <th width="30%">Draft Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in drafts" :key="item.Id">
                  <td>
                    <strong> {{ index + 1 }}. </strong>
                  </td>
                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="!item.isEditing"
                    >
                      {{ formatShortDate(item.EffectiveDate) }}
                    </span>
                    <DatePicker
                      mode="date"
                      :locale="'en-US'"
                      v-model="item.EffectiveDate"
                      v-show="item.isEditing"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          id="DC-effectiveDate"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Select Date"
                          @keypress="keyPress($event, item)"
                        />
                      </template>
                    </DatePicker>
                  </td>
                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="!item.isEditing"
                    >
                      {{ formatMoney(item.Amount) }}
                    </span>
                    <input
                      class="generalTextBox form-control"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                        allowMinus: false,
                      }"
                      v-show="item.isEditing"
                      v-model="item.Amount"
                      @keypress="keyPress($event, item)"
                    />
                  </td>
                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ item.Memo }}
                    </span>
                  </td>
                  <td>
                    <button
                      type="button"
                      title="Edit"
                      v-on:click.prevent.stop="handleEdit(item)"
                      class="btn"
                      style="
                        background-color: #24d171;
                        margin-right: 16px !important;
                      "
                    >
                      <i v-show="!item.isEditing" class="ri-edit-line"></i>
                      <i v-show="item.isEditing" class="fas fa-save"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="rightTabs[1].active && checkAuth(6642)">
            <table
            v-if="!isLoading"
              class="col-md-12 noMargin datagrid-table creditorTable"
              id="btn-editable-dc"
              style="border: none; background-color: rgb(255, 255, 255)"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Fee Date</th>
                  <th>Fee Amount</th>
                  <th>Fee Type</th>
                  <th>Payee</th>
                  <th>Acct #</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in feeList" :key="item.Id">
                  <td>
                    <strong> {{ index + 1 }}. </strong>
                  </td>

                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="!item.isEditing"
                    >
                      {{ formatShortDate(item.EffectiveDate) }}
                    </span>

                    <DatePicker
                      mode="date"
                      :locale="'en-US'"
                      v-model="item.EffectiveDate"
                      v-show="item.isEditing"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          id="DC-effectiveDate"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Select Date"
                          @keypress="keyPress($event, item)"
                        />
                      </template>
                    </DatePicker>
                  </td>

                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="!item.isEditing"
                    >
                      {{ formatMoney(item.Amount) }}
                    </span>
                    <input
                      class="generalTextBox form-control"
                      style="width: 150px"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                        allowMinus: false,
                      }"
                      v-show="item.isEditing"
                      v-model="item.Amount"
                      @keypress="keyPress($event, item)"
                    />
                  </td>

                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="
                        !item.isEditing ||
                        isDpgCustomer ||
                        isSettlementFee(item.Type) == true
                      "
                    >
                      {{ getFeeTypeName(item.Type) }}
                    </span>
                    <select
                      v-show="
                        item.isEditing &&
                        !(
                          isDpgCustomer == true ||
                          isSettlementFee(item.Type) == true
                        )
                      "
                      class="form-control"
                      v-model="item.Type"
                      :disabled="isDpgCustomer"
                    >
                      <option
                        v-for="i in applicableFeeTypes"
                        :value="i.FeeType"
                        :key="i.FeeType"
                      >
                        {{ i.Description }}
                      </option>
                    </select>
                  </td>
                  <td>
                    {{ item.CreditorName }}
                  </td>
                  <td>
                    {{ item.AccountNumber }}
                  </td>

                  <td style="float: right">
                    <button
                      v-if="
                        !isDpgCustomer ||
                        (isDpgCustomer &&
                          (getFeeTypeName(item.Type) == 'Performance Fee' ||
                            getFeeTypeName(item.Type) == 'ACH Credit Fee'))
                      "
                      type="button"
                      title="Edit"
                      v-on:click.prevent.stop="handleEdit(item)"
                      class="btn btn-success mr-2"
                      style="background-color: #24d171"
                    >
                      <i v-show="!item.isEditing" class="ri-edit-line"></i>
                      <i v-show="item.isEditing" class="fas fa-save"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <section
              v-if="feeList.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
              <div v-if="isLoading" class="loading">
                <div class="text-center" style="margin-top: 100px">
                  <i class="spinner-grow text-info m-2"></i>
                </div>
              </div>
            </section>
          </div>
          <div v-show="rightTabs[2].active && checkAuth(6643)">
            <table
              class="col-md-12 noMargin datagrid-table creditorTable"
              id="btn-editable-dc"
              style="border: none; background-color: rgb(255, 255, 255)"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Effective Date</th>
                  <th>Payee</th>
                  <th>Acct #</th>
                  <th>Payment Type</th>
                  <th>Pmt Amount</th>
                  <th v-if="isDpgCustomer == false">Fee Amount</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in paymentList"
                  :key="item.AccountNumber"
                >
                  <td>
                    <strong> {{ index + 1 }}. </strong>
                  </td>

                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ formatShortDate(item.EffectiveDate) }}
                    </span>
                  </td>

                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ item.Payee }}
                    </span>
                  </td>

                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ item.AccountNumber }}
                    </span>
                  </td>
                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ item.PaymentType }}
                    </span>
                  </td>
                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ formatMoney(item.PaymentAmount) }}
                    </span>
                  </td>
                  <td v-if="isDpgCustomer == false">
                    <span class="tabledit-span tabledit-identifier">
                      {{ formatMoney(item.FeeAmount) }}
                    </span>
                  </td>

                  <td class="noPadding">
                    <button
                      type="button"
                      title="Edit"
                      v-on:click.prevent.stop="openPaymentChangesPopup(item)"
                      class="btn btn-success"
                      style="background-color: #24d171"
                    >
                      <i class="ri-edit-line"></i>
                    </button>
                  </td>
                  <td class="noPadding">
                    <button
                      type="button"
                      title="Delete"
                      v-on:click.prevent.stop="deletePayment(item)"
                      class="btn btn-danger"
                    >
                      <i class="ri-delete-bin-line"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <section
              v-if="paymentList.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
              <div v-if="isLoading" class="loading">
                <div class="text-center" style="margin-top: 100px">
                  <i class="spinner-grow text-info m-2"></i>
                </div>
              </div>
            </section>
          </div>
          <div v-show="rightTabs[3].active && checkAuth(6654)">
            <table
              class="datagrid-table creditorTable"
              id="btn-editable-dc"
              style="
                border: none !important;
                background-color: rgb(255, 255, 255);
              "
            >
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th width="30%">Effective Date</th>
                  <th width="30%">Amount</th>
                  <th width="30%">Transaction Class/Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in chargeList" :key="item.Id">
                  <td>
                    <strong> {{ index + 1 }}. </strong>
                  </td>
                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="!item.isEditing"
                    >
                      {{ formatShortDate(item.EffectiveDate) }}
                    </span>
                    <DatePicker
                      mode="date"
                      :locale="'en-US'"
                      v-model="item.EffectiveDate"
                      v-show="item.isEditing"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          id="DC-effectiveDate"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Select Date"
                          @keypress="keyPress($event, item)"
                        />
                      </template>
                    </DatePicker>
                  </td>
                  <td>
                    <span
                      class="tabledit-span tabledit-identifier"
                      v-show="!item.isEditing"
                    >
                      {{ formatMoney(item.Amount) }}
                    </span>
                    <input
                      class="generalTextBox form-control"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                        allowMinus: false,
                      }"
                      v-show="item.isEditing"
                      v-model="item.Amount"
                      @keypress="keyPress($event, item)"
                    />
                  </td>
                  <td>
                    <span class="tabledit-span tabledit-identifier">
                      {{ item.TransactionCombined }}
                    </span>
                  </td>
                  <td>
                    <button
                      type="button"
                      title="Edit"
                      v-on:click.prevent.stop="handleEditCharges(item)"
                      class="btn"
                      style="
                        background-color: #24d171;
                        margin-right: 16px !important;
                      "
                    >
                      <i v-show="!item.isEditing" class="ri-edit-line"></i>
                      <i v-show="item.isEditing" class="fas fa-save"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <section
              v-if="chargeList.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
              <div v-if="isLoading" class="loading">
                <div class="text-center" style="margin-top: 100px">
                  <i class="spinner-grow text-info m-2"></i>
                </div>
              </div>
            </section>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isBulkRequestPopUp"
      style="align-items: center"
    >
      <div
        style="position: relative; margin: 2% auto"
        class="offset-lg-12 offset-lg-2 col-xl-6 col-lg-10 col-md-11 col-sm-12"
      >
        <BulkRequestPopUp
          :CustomerId="customerId"
          :OfficeId="officeId"
          :isDpg="isDpgCustomer"
          :feesTypesList="applicableFeeTypes"
          :cashFlowList="cashFlowListOld"
          @closeBulkRequestPopup="closeBulkRequestPopup"
          @calculateCashFlowForBulkDraft="calculateCashFlowFromChangePopups"
             @openCashFlowPanel="openCashFlowPanel"
        />
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isDraftChangesPopUp"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <DraftChangesPopUp
          @closeDraftChangesPopUp="closeDraftChangesPopUp"
          @submitDraftChanges="submitDraftChanges"
          @calculateCashFlowForDraftChanges="calculateCashFlowFromChangePopups"
          @openCashFlowPanel="openCashFlowPanel"
          :customerId="customerId"
          :changeDetails="draftChanges"
          :feesTypesList="applicableFeeTypes"
          :isDpg="isDpgCustomer"
          :cashFlowList="cashFlowListOld"
        />
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isFeeRequestPopUp"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <BulkFeeRequestPopUp
          :CustomerId="customerId"
          :OfficeId="officeId"
          :isDpg="isDpgCustomer"
           :cashFlowList="cashFlowListOld"
            @calculateCashFlowForBulkFee="calculateCashFlowFromChangePopups"
          @openCashFlowPanel="openCashFlowPanel"
          @closeFeeRequestPopup="closeFeeRequestPopup"
        />
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isChargeChangesPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <ChargeChangesPopup
          :customerId="customerId"
          :changeDetails="chargeChanges"
          :feesTypesList="applicableFeeTypes"
          :isDpg="isDpgCustomer"
          :cashFlowList="cashFlowListOld"
          @closeChargeChangesPopup="closeChargeChangesPopup"
          @submitChargeChanges="submitChargeChanges"
          @openCashFlowPanel="openCashFlowPanel"
          @calculateCashFlowForChargeChanges="calculateCashFlowFromChangePopups"
        />
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isBulkChargePopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <BulkChargeRequestPopup
          :CustomerId="customerId"
          :OfficeId="officeId"
          :PolicyGroupId="policyGroupId"
          :isDpg="isDpgCustomer"
          @closeBulkChargePopup="closeBulkChargePopup"
          :feesTypesList="applicableFeeTypes"
          :cashFlowList="cashFlowListOld"
          @calculateCashFlowForBulkCharge="calculateCashFlowFromChangePopups"
          @openCashFlowPanel="openCashFlowPanel"
        />
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isFeeChangesPopUp"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <FeeChangesPopUp
          @closeFeeChangesPopUp="closeFeeChangesPopUp"
          @submitFeeChanges="submitFeeChanges"
          @openCashFlowPanel="openCashFlowPanel"
          @calculateCashFlowForFeeChanges="calculateCashFlowFromChangePopups"
          :customerId="customerId"
          :changeDetails="feeChanges"
          :feesTypesList="applicableFeeTypes"
          :isDpg="isDpgCustomer"
          :allFeeList="feeList"
          :cashFlowList="cashFlowListOld"
        />
      </div>
    </div>

    <div class="backgroundLock" v-if="isBulkPaymentRequestPopUp">
      <div
        style="position: relative; margin: 2% auto"
        class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <BulkPaymentRequestPopUp
          @closePaymentRequestPopUp="closePaymentRequestPopUp"
          @openCashFlowPanel="openCashFlowPanel"
          @calculateCashFlowForBulkPayment="calculateCashFlowFromChangePopups"
          :customerId="customerId"
          :isDpg="isDpgCustomer"
          :customerOfficeId="officeId"
          :policyGroupId="policyGroupId"
          :feesTypesList="applicableFeeTypes"
          :cashFlowList="cashFlowListOld"
        />
      </div>
    </div>

    <div class="backgroundLock" v-if="isPaymentChangesPopUp">
      <div
        style="
          position: relative;
          margin: 2% auto;
          min-height: 520px;
          max-height: 90%;
          overflow: auto;
        "
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <PaymentChangesPopUp
          @closePaymentChangesPopup="closePaymentChangesPopup"
          @submitPaymentChanges="submitPaymentChanges"
          @calculateCashFlowForPaymentChanges="calculateCashFlowFromChangePopups"
          @openCashFlowPanel="openCashFlowPanel"
          :customerId="customerId"
          :customerOfficeId="officeId"
          :paymentObj="selectedPayment"
          :feesTypesList="applicableFeeTypes"
          :isDpg="isDpgCustomer"
          :allPaymentList="paymentList"
          :cashFlowList="cashFlowListOld"
          :policyGroupId ="policyGroupId"
        />
      </div>
    </div>

    

    <div class="backgroundLock" v-if="isDeletePaymentPopupOpen">
      <div
        style="
          position: relative;
          margin: 10% auto;
          max-height: 90%;
          overflow: auto;
        "
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <PaymentDeletePopUp
          @closePaymentDeletePopup="closePaymentDeletePopup"
          @calculateCashFlowForPaymentChanges="calculateCashFlowFromChangePopups"
          @openCashFlowPanel="openCashFlowPanel"
          :customerId="customerId"
          :isDpg="isDpgCustomer"
          :customerOfficeId="officeId"
          :payment="deletedPayment"
          :feesTypesList="applicableFeeTypes"
          :cashFlowList = "cashFlowListOld"
        />
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import DraftChangesPopUp from "./DraftChangesPopUp";
import { DatePicker } from "v-calendar";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import BulkRequestPopUp from "./BulkRequestPopUp.vue";
import BulkFeeRequestPopUp from "./BulkFeeRequestPopUp.vue";
import FeeChangesPopUp from "./FeeChangesPopUp";
import BulkPaymentRequestPopUp from "./BulkPaymentRequestPopUp";
import PaymentChangesPopUp from "./PaymentChangesPopUp";
import PaymentDeletePopUp from "./PaymentDeletePopUp";
import ChargeChangesPopup from "./ChargeChangesPopup";
import BulkChargeRequestPopup from "./BulkChargeRequestPopup";
import gcsTypes from "@/views/agent/EditClient/GCS/types";
import Moveable from "vue3-moveable";
export default {
  name: "DraftChanges",
  mixins: [utilitiesMixin, formatMixin],
  components: {
    DatePicker,
    DraftChangesPopUp,
    BulkRequestPopUp,
    BulkFeeRequestPopUp,
    FeeChangesPopUp,
    BulkPaymentRequestPopUp,
    PaymentChangesPopUp,
    PaymentDeletePopUp,
    ChargeChangesPopup,
    BulkChargeRequestPopup,
    Moveable,
  },

  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },

    officeId: {
      type: Number,
      default: () => 0,
    },
    policyGroupId: {
      type: Number,
      default: () => 0,
    },
    isDpgCustomer: {
      type: Boolean,
      default: () => false,
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
    paymentOptionId: {
      type: Number,
      default: () => 1,
    },
    dayOfMonth: {
      type: Number,
      default: () => 0,
    },
    draftDate: {
      type: Date,
      default: () => null,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
    "extraPaymentData.DraftDate": async function (newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.calculateCashFlowForExtraPayment();
      }
    },
  },
  emits: ["refreshData"],
  data() {
    return {
      moveable: {
        target: [".cashflowMoveableDraft"],
        draggable: true,
        scalable: false,
        rotatable: false,
        pinchable: false,
        origin: false,
      },
      tableLoading: false,
      cashFlowListRef:[],
      isCashFlowPanelVisible: false,
      isNegativeCashFlow: false,
      isNegativeCashFlowForExtraDraft: false,
      isLoadingApproveButton: false,
      isLoading: false,
      paymentList: [],
      paymentChanges: [],
      paymentChangeOldData: null,
      paymentTypesList: [],

      chargesChangeOldData: null,
      chargeList: [],
      chargeChanges: [],
      isChargeChangesPopupOpen: false,
      isBulkChargePopupOpen: false,

      selectedPayment: null,
      deletedPayment: null,
      isBulkPaymentRequestPopUp: false,
      isPaymentChangesPopUp: false,
      isDeletePaymentPopupOpen: false,
      feeList: [],
      isFeeChangesPopUp: false,
      isFeeRequestPopUp: false,
      notAuhtorizedTab: false,
      draftChanges: [],
      drafts: [],
      extraDraftsTab: false,
      isOpenSkipPaymentApplicableFees: false,
      isDraftChangesPopUp: false,

      isBulkRequestPopUp: false,
      tabs: [
        {
          id: 1,
          name: "skipPayment",
          label: "Skip Payment",
          iconName: "",
          active: true,
          authorizationId: 6644,
        },
        {
          id: 2,
          name: "extraPayment",
          label: "Extra Payment",
          iconName: "",
          active: false,
          authorizationId: 6645,
        },
        {
          id: 3,
          name: "additionalComment",
          label: "Additional Comment",
          iconName: "",
          active: false,
          authorizationId: 6646,
        },
      ],
      rightTabs: [
        {
          id: 1,
          name: "draftChanges",
          label: "Draft Changes",
          iconName: "",
          active: true,
          authorizationId: 6641,
        },
        {
          id: 2,
          name: "fees",
          label: "Fee Changes",
          iconName: "",
          active: false,
          authorizationId: 6642,
        },
        {
          id: 3,
          name: "paymentChanges",
          label: "Payment Changes",
          iconName: "",
          active: false,
          authorizationId: 6643,
        },
        {
          id: 4,
          name: "chargesChanges",
          label: "Charges Changes",
          iconName: "",
          active: false,
          authorizationId: 6654,
        },
      ],
      skipPaymentData: {
        PaymentCount: 0,
        DraftCount: 0,
        IsDividePayments: false,
        Notes: "",
        IsEmergency: false,
        ApplicableFees: [],
      },
      additionalCommentData: {
        IsEmergency: false,
        Notes: "",
      },

      extraPaymentData: {
        IsEmergency: false,
        Notes: "",
        DraftAmount: 0,
        DraftTypeId: null,
        DraftDate: "",
        ApplicableFees: [],
      },

      extraFeesTabData: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },

      skipyPaymentFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },

      draftChangeOldData: null,
      modelConfig: {
        type: "date",
        mask: "MM/DD/YYYY",
      },
      extraPaymentDraftTypes: [],
      feesTypesList: [],
      feeChanges: [],
      feeChangeOldData: null,
      cashFlowList: [],
      cashFlowListOld: [],
      ongoingSettlements: [],
      calculateWithOngoingSettlements: false,
      cashFlowModifiedListBackup: [],
    };
  },
  computed: mapState({

    applicableFeeTypes() {
      return this.feesTypesList && this.feesTypesList.length > 0
        ? this.feesTypesList.filter((x) => x.IsSettlementFee != true)
        : [];
    },
  }),
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });
      if (
        !arrPermission.includes(6644) &&
        !arrPermission.includes(6645) &&
        !arrPermission.includes(6646) &&
        !arrPermission.includes(6641) &&
        !arrPermission.includes(6642) &&
        !arrPermission.includes(6643) &&
        !arrPermission.includes(6654)
      ) {
        this.notAuhtorizedTab = true;
      } else {
        this.setFirstTab();
      }
    }
  },
  methods: {
    ongoingSettlementChange(){
 
      let list = deepCopy(this.cashFlowModifiedListBackup);
      for(let item of list)
      {
        delete item.IsDraft;
        delete item.IsPay;
        delete item.IsDeletedDraft;
        delete item.IsDeletedPay;
        delete item.IsModifiedDraft;
        delete item.IsModifiedFee; 
      }
      this.calculateCashFlow(new Date(),list);

    },
    calculateCashFlowFromChangePopups(list) {
      if (this.checkAuth(956782)) {
        this.calculateCashFlow(new Date(), list);
      }
    },
    checkCashFlowNegative(list) {
      let rtn = false;
      if (this.checkAuth(956782)) {
        rtn =
          list.filter((x) => x.Balance < 0 && new Date(x.Date) >= new Date())
            .length > 0;
      }
      return rtn;
    },
    checkCashFlowNegativeForPopups() {
      let rtn = false;
      if (this.checkAuth(956782)) {
        rtn =
          this.cashFlowList.filter((x) => x.Balance < 0 && new Date(x.Date) >= new Date())
            .length > 0;
      }
      return rtn;
    },
    closeCashFlowPanel() {
      this.isCashFlowPanelVisible = false;
      this.calculateWithOngoingSettlements = false;
      let list = deepCopy(this.cashFlowModifiedListBackup);
      this.calculateCashFlow(new Date(),list);
      this.cashFlowList= deepCopy(this.cashFlowListRef);
    },
    openCashFlowPanel() {
      this.isCashFlowPanelVisible = true;
      this.calculateWithOngoingSettlements = false;
      this.cashFlowModifiedListBackup = deepCopy(this.cashFlowList);
      this.cashFlowListRef = deepCopy(this.cashFlowList);
    },
    handleDrag({ target, transform }) {
      if (this.isTouchDevice) {
        return;
      }
      try {
        //matrix(1, 0, 0, 1, 0, 0) translate(-308px, -701px)
        if (target && transform.includes("translate")) {
          let index = transform.indexOf("translate(");
          let str = transform.slice(index, transform.length);
          str = str.replace("translate(", "");
          str = str.replace(")", "");

          let maxHeight = window.innerHeight;
          let customerPanel = document.getElementById("draftChagesSection");
          if (typeof customerPanel != "undefined" && customerPanel != null) {
            maxHeight = customerPanel.clientHeight;
          }

          if (str.length > 0) {
            let positions = str.split(",");
            if (positions.length > 0) {
              let posX = parseInt(positions[0].replace("px", ""));
              let posY = parseInt(positions[1].replace("px", ""));
              let winWidth = window.innerWidth;
              let controlWidth = target.offsetWidth;
              let controlHeight = target.offsetHeight;
              let minX = -controlWidth + 50;
              let minY = -controlHeight + 50;
              if (
                posX > minX &&
                posY > minY &&
                posX < parseInt(winWidth - controlWidth) &&
                posY < parseInt(maxHeight)
              ) {
                target.style.transform = transform;
              }
            }
          }
        }
      } catch {
        //todo:log errors
      }
    },
    async bindCashFlowData() {
      if (this.checkAuth(956782)) {
        if (this.customerId && this.officeId) {
          this.tableLoading = true;
          let err, result;
          [err, result] = await this.$store.dispatch(
            gcsTypes.GET_CASHFLOW_DATA,
            {
              CustomerId: this.customerId,
              OfficeId: this.officeId,
            }
          );
          if (result && result.Data) {
            if (result.Data.CashFlows && result.Data.CashFlows.length > 0) {
              this.cashFlowList = deepCopy(result.Data.CashFlows);
              this.cashFlowListOld = deepCopy(result.Data.CashFlows);
              this.isNegativeCashFlow = this.checkCashFlowNegative(
                this.cashFlowList
              );
            }

            if (result.Data.OngoingSettlements) {
              this.ongoingSettlements = deepCopy(
                result.Data.OngoingSettlements
              );
            }
          }
        }
        this.tableLoading = false;
      }
    },
    async calculateCashFlow(paymentDay, list) {
    
      let tmpList = deepCopy(list);

      for(let item of tmpList)
      {
        let hourValue = 13;
         if(item.IsDraft)
         {
           hourValue=12;
         }
          let d = new Date(item.Date);
          if(!isNaN(d))
          {
            item.Date = new Date(d.getFullYear(),d.getMonth(),d.getDate(),hourValue,0,0);
          }
        
         
      }

      if (this.checkAuth(956782)) {
        if (this.calculateWithOngoingSettlements == true) {
          for (let i of this.ongoingSettlements) {
            tmpList.push({
              Id: "X",
              ReferenceId:"X",
              TransactionType: "P",
              Date: i.PaymentDate,
              Amount: i.PaymentAmount + i.FeeAmount,
              Balance: 0,
              Memo:
                i.CreditorName + " - " + this.formatShortDate(i.PaymentDate),
                IsPay: true,
            });
          }
        }



        tmpList.sort((a, b) => new Date(a.Date) - new Date(b.Date));

        for (let i = 0; i < tmpList.length; i++) {

          if (tmpList[i].IsPay == true) {
            if(i== 0)
          {
            tmpList[i].Balance =  parseFloat(parseFloat(tmpList[i].Amount).toFixed(2))*-1;
          }
            else if (i > 0) {
              tmpList[i].Balance = parseFloat(
                parseFloat(tmpList[i - 1].Balance - tmpList[i].Amount).toFixed(2)
              );
              tmpList[i].Id = parseInt(tmpList[i - 1].Id + 1);
            }

            for (let j = i + 1; j < tmpList.length; j++) {
              tmpList[j].Balance = parseFloat(
                parseFloat(tmpList[j].Balance - tmpList[i].Amount).toFixed(2)
              );
              tmpList[j].Id += 1;
            }
          }
          else if (tmpList[i].IsDeletedDraft == true) {
       
            if(i < tmpList.length)
            {
              for (let j = i; j < tmpList.length; j++) {
               
                tmpList[j].Balance = parseFloat(
                  parseFloat(tmpList[j].Balance - tmpList[i].Amount).toFixed(2)
                );
                
              }
           
            }
          }
          else if (tmpList[i].IsDeletedPay == true) {
       
       if(i < tmpList.length)
       {
         for (let j = i; j < tmpList.length; j++) {
          
          tmpList[j].Balance = parseFloat(
             parseFloat(tmpList[j].Balance + tmpList[i].Amount).toFixed(2)
           );
           
         }
      
       }
     } 
          else if (tmpList[i].IsDraft == true) {
            if (i > 0) {
              tmpList[i].Balance = parseFloat(
                parseFloat(tmpList[i - 1].Balance + tmpList[i].Amount).toFixed(2)
              );

              tmpList[i].Id = parseInt(tmpList[i - 1].Id + 1);
            }

            for (let j = i + 1; j < tmpList.length; j++) {
              tmpList[j].Balance = parseFloat(
                parseFloat(tmpList[j].Balance + tmpList[i].Amount).toFixed(2)
              );
              tmpList[j].Id += 1;
            }
          } else if (tmpList[i].IsModifiedDraft == true) {
            let indx = this.cashFlowListOld.findIndex(
              (x) => x.ReferenceId == tmpList[i].ReferenceId
            );
            if (indx > -1) {
              if (i > 0) {
                tmpList[i].Balance = parseFloat(
                parseFloat(tmpList[i - 1].Balance + tmpList[i].Amount).toFixed(2)
              );

              tmpList[i].Id = parseInt(tmpList[i - 1].Id + 1);
            }
                 for (let j = i + 1; j < tmpList.length; j++) {

                  let addedAmount = parseFloat(tmpList[i].Amount - this.cashFlowListOld[indx].Amount);
                  tmpList[j].Balance = parseFloat(
                    parseFloat(tmpList[j].Balance + addedAmount).toFixed(2)
                  );
                  tmpList[j].Id += 1;
                }

              
           
             
            }
          } else if (tmpList[i].IsModifiedFee == true) {
            let indx = this.cashFlowListOld.findIndex(
              (x) => x.Id == tmpList[i].Id
            );
            if (indx > -1) {
              tmpList[i].Balance = parseFloat(
                parseFloat(tmpList[i - 1].Balance - parseFloat(tmpList[i].Amount))
              );
              tmpList[i].Id = parseInt(tmpList[i - 1].Id + 1);

              for (let j = i + 1; j < tmpList.length; j++) {
                tmpList[j].Balance = parseFloat(
                  parseFloat(tmpList[j].Balance - tmpList[i].Amount).toFixed(2)
                );
                tmpList[j].Id += 1;
              }
            }
          }
        }

        let deletedList = tmpList.filter(x=> x.IsDeletedDraft || x.IsDeletedPay);
        if(deletedList && deletedList.length> 0)
        {
          for(let item of deletedList)
          {
              let dIndex = tmpList.indexOf(item);
              
              if(dIndex> -1)
              {
                tmpList.splice(dIndex,1);
              }
          }
        }
        
        this.tableLoading = true;
        this.cashFlowList = deepCopy(tmpList);
        this.isNegativeCashFlow= this.checkCashFlowNegative( this.cashFlowList);
        this.tableLoading = false;
      }
    },

    calculateCashFlowForSkipPayment(paymentCount, divideCount = 0) {
      if (this.checkAuth(956782)) {
        let paymentCountInt = parseInt(paymentCount);
        let divideCountInt = parseInt(divideCount);
        let list = deepCopy(this.cashFlowListOld);
        if (paymentCountInt > 0) {
          if (divideCountInt > 0) {
            let draftsLocalDivide = _orderby.orderBy(
              this.cashFlowListOld.filter(
                (x) => x.TransactionTypeDesc == "ACH Monthly Draft"
              ),
              "Date",
              "asc"
            );
            if (!(draftsLocalDivide.length >= paymentCountInt)) {
              this.$swal(
                "Warning",
                "Active draft count in Global is less than requested skip + divide payments.",
                "warning"
              );
              return;
            }

            if (divideCountInt >= draftsLocalDivide.length - paymentCountInt) {
              this.$swal(
                "Warning",
                "Active draft count in Global is less than requested skip + divide payments.",
                "warning"
              );
              return;
            }

            let totalDraftAmount = 0;
            for (let i = 0; i < paymentCountInt; i++) {
              totalDraftAmount += parseFloat(draftsLocalDivide[i].Amount);
            }
            let dividedAmount = parseFloat(
              parseFloat(totalDraftAmount / divideCountInt).toFixed(2)
            );

            for (
              let i = paymentCountInt;
              i < divideCountInt + paymentCountInt;
              i++
            ) {
              let indx = list.findIndex((x) => x.Id == draftsLocalDivide[i].Id);
              if (indx > -1) {
                list[indx].Amount = parseFloat(
                  (
                    parseFloat(list[indx].Amount) + parseFloat(dividedAmount)
                  ).toFixed(2)
                );
                list[indx].IsModifiedDraft = true;
              }
            }

            for (let i = 0; i < paymentCountInt; i++) {
              let indx = list.findIndex((x) => x.Id == draftsLocalDivide[i].Id);
              if (indx > -1) {
                list[indx].IsDeletedDraft = true;
              }
            }
            this.calculateCashFlow(new Date(), list);
          } else {
            let draftsLocal = _orderby.orderBy(
              this.cashFlowListOld.filter(
                (x) => x.TransactionTypeDesc == "ACH Monthly Draft"
              ),
              "Date",
              "asc"
            );
            if (draftsLocal.length > 0) {

              let tmpDrafts= deepCopy(this.drafts);


              let firstDay =
                this.draftDate != null ? new Date(this.draftDate).getDate() : 0;
              let secondDay = this.dayOfMonth;
              let isFirstDayStart = true;


              let draftList = tmpDrafts
                ? tmpDrafts.reverse()
                : [];
              let latestDraftDate = null;
              if (draftList.length > 0) {
                latestDraftDate = draftList[0].EffectiveDate;
              }
              let newDraftDate = null;

          
              let latestMonth = new Date(latestDraftDate).getMonth();
              let latestYear = new Date(latestDraftDate).getFullYear();

              let nextFirstDate = new Date(latestYear, latestMonth, firstDay);
              let nextSecondDate = new Date(latestYear, latestMonth, secondDay);

              if (nextFirstDate <= new Date(latestDraftDate)) {
                nextFirstDate = this.addMonthsFix(nextFirstDate, 1);
              }

              if (nextSecondDate <= new Date(latestDraftDate)) {
                nextSecondDate = this.addMonthsFix(nextSecondDate, 1);
              }

              if (nextFirstDate > nextSecondDate) {
                isFirstDayStart = false;
              }

              if(this.paymentOptionId != 2){
              for(let  i=0; i< draftsLocal.length; i++)
              {
                let indx = list.findIndex((x) => x.Id == draftsLocal[i].Id);
                if (indx > -1) {
                  list[indx].IsDeletedDraft = true;
                }
                let modifiedDraftDate = null;
                if (this.paymentOptionId == 1) {
                  //monthly
                  modifiedDraftDate = this.addMonthsFix(list[indx].Date, paymentCount);
                  list.push({
                  Id: "X",
                  IsDraft: true,
                  TransactionType: draftsLocal[i].TransactionType,
                  TransactionTypeDesc: draftsLocal[i].TransactionTypeDesc,
                  Date: new Date(modifiedDraftDate).toISOString(),
                  Amount: draftsLocal[i].Amount,
                  Memo: draftsLocal[i].Memo,
                  Balance: 0,
                });
                } 

                else if (this.paymentOptionId == 3) {
                  //Bi-Weekly
                  modifiedDraftDate = this.addDays(list[indx].Date, paymentCount*14);
                  list.push({
                  Id: "X",
                  IsDraft: true,
                  TransactionType: draftsLocal[i].TransactionType,
                  TransactionTypeDesc: draftsLocal[i].TransactionTypeDesc,
                  Date: new Date(modifiedDraftDate).toISOString(),
                  Amount: draftsLocal[i].Amount,
                  Memo: draftsLocal[i].Memo,
                  Balance: 0,
                });
                } else if (this.paymentOptionId == 4) {
                  // Weekly
           
                  modifiedDraftDate = this.addDays(list[indx].Date, paymentCount*7);
                  list.push({
                  Id: "X",
                  IsDraft: true,
                  TransactionType: draftsLocal[i].TransactionType,
                  TransactionTypeDesc: draftsLocal[i].TransactionTypeDesc,
                  Date: new Date(modifiedDraftDate).toISOString(),
                  Amount: draftsLocal[i].Amount,
                  Memo: draftsLocal[i].Memo,
                  Balance: 0,
                });
                }
                
              }
              }
              else
              {
                for (let i = 0; i < paymentCount; i++) {
                if (this.paymentOptionId == 2) {
                  //  Twice a month

                  let addMonths = Math.floor(i / 2);
                  if (isFirstDayStart) {
                    //means starts with first draft day
                    if (i % 2 == 0) {
                      newDraftDate = this.addMonthsFix(
                        nextFirstDate,
                        addMonths
                      );
                    } else {
                      newDraftDate = this.addMonthsFix(
                        nextSecondDate,
                        addMonths
                      );
                    }
                  } else {
                    // means starts with twice a month value
                    if (i % 2 == 0) {
                      newDraftDate = this.addMonthsFix(
                        nextSecondDate,
                        addMonths
                      );
                    } else {
                      newDraftDate = this.addMonthsFix(
                        nextFirstDate,
                        addMonths
                      );
                    }
                  }
                }

                list.push({
                  Id: "X",
                  IsDraft: true,
                  TransactionType: draftsLocal[i].TransactionType,
                  TransactionTypeDesc: draftsLocal[i].TransactionTypeDesc,
                  Date: new Date(newDraftDate).toISOString(),
                  Amount: draftsLocal[i].Amount,
                  Memo: draftsLocal[i].Memo,
                  Balance: 0,
                });
              }
              for (let i = 0; i < paymentCount; i++) {
                let indx = list.findIndex((x) => x.Id == draftsLocal[i].Id);
                if (indx > -1) {
                  list[indx].IsDeletedDraft = true;
                }
              }
              }
             
              this.calculateCashFlow(new Date(), list);
            }
          }
        }
        else
        {
          this.calculateCashFlow(new Date(), list);
        }
        
      }
    },
    calculateCashFlowForExtraPayment() {
      if (this.checkAuth(956782)) {
        let extraDraftAmount = parseFloat(this.extraPaymentData.DraftAmount);
        let extraDraftDate = new Date(this.extraPaymentData.DraftDate);
        let fees = deepCopy(this.extraPaymentData.ApplicableFees);
        let list = deepCopy(this.cashFlowListOld);

        if (
          extraDraftAmount > 0 &&
          extraDraftDate != null &&
          extraDraftDate != ""
        ) {
          list.push({
            Id: "X",
            IsDraft: true,
            TransactionType: "A",
            TransactionTypeDesc: "ACH Monthly Draft",
            Date: extraDraftDate.toString("MM/dd/yyyy"),
            Amount: extraDraftAmount,
            Memo: "Extra Draft",
            Balance: 0,
          });
        }
        if (fees && fees.length > 0) {
          for (let item of fees) {
            let feeAmount = parseFloat(item.FeeAmount);
            let feeDate = new Date(item.FeeDate);
            list.push({
              Id: "X",
              IsPay: true,
              TransactionType: "P",
              TransactionTypeDesc: "",
              Date: feeDate.toString("MM/dd/yyyy"),
              Amount: feeAmount,
              Memo: "Applicable Fee",
              Balance: 0,
            });
          }
        }

        this.calculateCashFlow(new Date(), list);

        this.isNegativeCashFlowForExtraDraft = this.checkCashFlowNegative(
          this.cashFlowList
        );
      }
    },
    setFirstTab() {

      let authorizedTabs = this.tabs.filter((x) =>
        this.checkAuth(x.authorizationId)
      );
      if (authorizedTabs.length > 0) {
        this.handleTabChange(authorizedTabs[0].name);
      }

      let authorizedRightTabs = this.rightTabs.filter((x) =>
        this.checkAuth(x.authorizationId)
      );
      if (authorizedRightTabs.length > 0) {
        this.handleRightTabChange(
          authorizedRightTabs[0].id,
          authorizedRightTabs[0].name,
          true
        );
      }
    },
    isSettlementFee(feeType) {
      let rtn = false;

      if (this.feesTypesList.length > 0) {
        rtn = this.feesTypesList.some(
          (x) => x.FeeType == feeType && x.IsSettlementFee == true
        );
      }

      return rtn;
    },
    openBulkChargePopup() {
      this.isBulkChargePopupOpen = true;
    },

    closeBulkChargePopup(refreshGrid) {
      this.isBulkChargePopupOpen = false;
      if (refreshGrid) {
        this.bindChargesGrid(true);
        this.bindCashFlowData();
      }
      else{
        this.cashFlowList= deepCopy(this.cashFlowListOld);
      }
    },
    closePaymentDeletePopup(refreshGrid) {
      this.isDeletePaymentPopupOpen = false;
      if (refreshGrid) {
        this.bindPaymentGrid(true);
        this.bindCashFlowData();
      }
      else{
        this.cashFlowList= deepCopy(this.cashFlowListOld);
      }
    },
    deletePayment(item) {
      this.deletedPayment = {
        EffectiveDate: new Date(item.EffectiveDate).toString("yyyy-MM-dd"),
        NewDate: new Date(item.EffectiveDate).toString("yyyy-MM-dd"),
        PayeeName: item.Payee,
        PayeeId: item.PayeeId,
        PaymentId: item.PaymentId,
        PayeeAccountNumber: item.AccountNumber,
        PaymentType: item.PaymentType,
        PaymentAmount: 0,
        FeeAmount: 0,
      };
      this.isDeletePaymentPopupOpen = true;
    },
    // deleteCharges(item) {
    //   this.deletedPayment = {
    //     EffectiveDate: new Date(item.EffectiveDate).toString("yyyy-MM-dd"),
    //     Amount: item.Amount,
    //     Status: item.Status,

    //   };
    //   this.isDeletePaymentPopupOpen = true;
    // },
    openPaymentChangesPopup(item) {
      this.selectedPayment = deepCopy(item);
      this.isPaymentChangesPopUp = true;
    },
    openChargesChangesPopup(item) {
      this.selectedPayment = deepCopy(item);
      this.isPaymentChangesPopUp = true;
    },
    closePaymentChangesPopup() {
      this.isPaymentChangesPopUp = false;
      this.cashFlowList= deepCopy(this.cashFlowListOld);
    },

    submitPaymentChanges(refreshGrid) {
      this.isPaymentChangesPopUp = false;
      if (refreshGrid) {
        this.bindPaymentGrid(true);
        this.bindCashFlowData();
      } else {
        this.paymentChanges = deepCopy(this.paymentChangeOldData);
      }
    },

    openPaymentRequestPopUp() {
      this.isBulkPaymentRequestPopUp = true;
    },
    async closePaymentRequestPopUp(refreshGrid) {
      this.isBulkPaymentRequestPopUp = false;
      if (refreshGrid) {
        await this.bindPaymentGrid(true);
        this.bindCashFlowData();
      }
      else{
        this.cashFlowList= deepCopy(this.cashFlowListOld);
      }
    },

    async bindFeesGrid(refresh) {
      if (this.feeList.length == 0 || refresh == true) {
        this.isLoading = true;
        let err,result;
        [err,result] =await this.$store.dispatch(types.GET_FEE_CHANGES_GRID, {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        });

        if(result && result.Data)
        {
         
         let fees =    
    result.Data.length > 0
          ? _orderby.orderBy(
            result.Data
                .filter((x) => x.Active == "Y")
                .map((x) => {
                  return {
                    EffectiveDate: new Date(x.EffectiveDate),
                    DebitId: x.DebitId,
                    Amount: x.Amount,
                    Type:
                      x.Type && x.Type.length > 0
                        ? this.getFeeTypeKey(x.Type)
                        : "",
                    SettlementId: x.SettlementId,
                    isEditing: false,
                    CreditorId: x.CreditorId,
                    CreditorName: x.CreditorName,
                    AccountNumber: x.AccountNumber,
                  };
                }),
              "EffectiveDate",
              "asc"
            )
          : [];
  

           this.feeChangeOldData = deepCopy(fees);
           this.feeList = deepCopy(fees);
        }
        
        this.isLoading = false;
      }
    },
    async bindPaymentGrid(refresh) {
      if (this.paymentList.length == 0 || refresh == true) {
        this.isLoading = true;
        let err,result;
        [err,result] = await this.$store.dispatch(types.GET_PAYMENT_CHANGES_GRID, {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        });
      if(result && result.Data)
      {
         let paymentData  =  result.Data.length > 0
          ? _orderby.orderBy(
            result.Data
                .filter(
                  (x) =>
                    x.Active == "Y" &&
                    ((this.isDpgCustomer && x.PaymentAmount > 0) ||
                      !this.isDpgCustomer)
                )
                .map((x) => {
                  return {
                    EffectiveDate: new Date(x.EffectiveDate),
                    Payee: x.Payee,
                    PayeeId: x.PayeeId,
                    PaymentId: x.PaymentId,
                    AccountNumber: x.AccountNumber,
                    PaymentType: x.PaymentType,
                    PaymentAmount: x.PaymentAmount,
                    FeeAmount: x.FeeAmount,
                    SettlementId: x.SettlementId,
                    CreditorId: x.CreditorId,
                  };
                }),
              "EffectiveDate",
              "asc"
            )
          : [];
          this.paymentChangeOldData = deepCopy(paymentData);
        this.paymentList = deepCopy(paymentData);
      }
        
        this.isLoading = false;
      }
    },

    async bindChargesGrid(refresh) {
      if (
        this.isDpgCustomer != true &&
        (this.chargeList.length == 0 || refresh == true)
      ) {
        this.isLoading = true;
        let err,result;
        [err,result] = await this.$store.dispatch(types.GET_CHARGES_CHANE_GRID, {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        });
        if(result && result.Data)
        {
           let chargesData = result.Data.length > 0
          ? _orderby.orderBy(
            result.Data
                .filter(
                  (x) =>
                    x.Active == "Y" &&
                    ((this.isDpgCustomer && x.Amount > 0) ||
                      !this.isDpgCustomer)
                )
                .map((x) => {
                  return {
                    EffectiveDate: new Date(x.EffectiveDate),
                    ChargeId: x.ChargeId,
                    Amount: x.Amount,
                    TransactionCombined: x.TransactionCombined,
                  };
                }),
              "EffectiveDate",
              "asc"
            )
          : [];
          this.chargesChangeOldData = deepCopy(chargesData);
          this.chargeList = deepCopy(chargesData);
        }
      
        this.isLoading = false;
      }
    },

    openFeeRequestPopUp() {
      this.isFeeRequestPopUp = true;
    },

    async closeFeeRequestPopup(refreshFeeGrid) {
      this.isFeeRequestPopUp = false;
      if (refreshFeeGrid) {
        this.bindFeesGrid(true);
        this.bindCashFlowData();
      }
      else{
        this.cashFlowList= deepCopy(this.cashFlowListOld);
      }
    },

    openFeeChangePopup() {
      for (let item of this.feeList) {
        item.Amount = parseFloat(item.Amount);
      }

      if (
        JSON.stringify(this.feeChangeOldData) == JSON.stringify(this.feeList)
      ) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }

      this.feeChanges = [];
      for (let i = 0; i < this.feeList.length; i++) {
        if (
          new Date(this.feeList[i].EffectiveDate).toString("yyyy-MM-dd") !=
            new Date(this.feeChangeOldData[i].EffectiveDate).toString(
              "yyyy-MM-dd"
            ) ||
          this.feeList[i].Amount != this.feeChangeOldData[i].Amount ||
          this.feeList[i].Type != this.feeChangeOldData[i].Type
        ) {
          this.feeChanges.push({
            DebitId: this.feeList[i].DebitId,
            OriginalDate: new Date(
              this.feeChangeOldData[i].EffectiveDate
            ).toString("yyyy-MM-dd"),
            FeeDate: new Date(this.feeList[i].EffectiveDate).toString(
              "yyyy-MM-dd"
            ),
            GlobalFeeType: this.feeList[i].Type,
            GlobalDebitId: this.feeList[i].DebitId,
            FeeAmount: this.feeList[i].Amount,
            SettlementId: this.isDpgCustomer ? this.feeList[i].SettlementId : 0,
            CreditorId: this.feeList[i].CreditorId,
            CreditorName: this.feeList[i].CreditorName,
            AccountNumber: this.feeList[i].AccountNumber,
          });
        }
      }
      this.isFeeChangesPopUp = true;
    },

    closeFeeChangesPopUp() {
      this.isFeeChangesPopUp = false;
      this.cashFlowList = deepCopy(this.cashFlowListOld);
    },

    openBulkRequestPopUp() {
      this.isBulkRequestPopUp = true;
    },
    closeBulkRequestPopup(refreshGrid) {
      this.isBulkRequestPopUp = false;
      if (refreshGrid) {
        this.bindDraftGrid(true);
        this.bindCashFlowData();
      }
      else
      {
        this.cashFlowList = deepCopy(this.cashFlowListOld);
      }
    },
    async bindDraftGrid(refresh) {
      if (this.drafts.length == 0 || refresh == true) {
        this.isLoading = true;
        let err,result;
        [err,result] = await this.$store.dispatch(types.GET_DRAFT_CHANGES_GRID, {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        });
        if(result && result.Data)
        {
           let dataList = result.Data.length > 0
          ? _orderby.orderBy(
            result.Data
                .filter((x) => x.Active == "Y")
                .map((x) => {
                  return {
                    EffectiveDate: new Date(x.EffectiveDate),
                    DebitId: x.DebitId,
                    Amount: x.Amount,
                    Memo: x.Memo,
                    isEditing: false,
                  };
                }),
              "EffectiveDate",
              "asc"
            )
          : [];
          this.draftChangeOldData = deepCopy(dataList);
          this.drafts = deepCopy(dataList);
        }
       
        this.isLoading = false;
      }
    },
    async watcherRefresh() {
      
      await this.getExtraPaymentDraftTypes();
      await this.getFeeTypes();
      await this.bindCashFlowData();
      this.setFirstTab();
     
    },

    emptyTheObjectsOnUnmount() {
      this.notAuhtorizedTab = null;
      this.draftChanges = null;
      this.drafts = null;
      this.extraDraftsTab = null;
      this.isOpenSkipPaymentApplicableFees = null;
      this.isDraftChangesPopUp = null;
      this.tabs = null;
      this.skipPaymentData = null;
      this.additionalCommentData = null;
      this.extraPaymentData = null;
      this.extraFeesTabData = null;
      this.skipyPaymentFees = null;
      this.draftChangeOldData = null;
      this.modelConfig = null;
      this.extraPaymentDraftTypes = null;
      this.feesTypesList = null;
    },

    async getExtraPaymentDraftTypes() {
      if (this.extraPaymentDraftTypes.length > 0) return;

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_EXTRA_PAYMENT_DRAFT_TYPES
      );
      if (result && result.Data) {
        this.extraPaymentDraftTypes = deepCopy(result.Data);
      }
    },
    async getFeeTypes() {
      if (this.feesTypesList.length > 0) return;

      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_FEES_TYPES, {
        CustomerId: this.customerId,
        OfficeId: this.officeId,
      });
      if (result && result.Data) {
        this.feesTypesList = deepCopy(result.Data);
      }
    },
    getFeeTypeName(value) {
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].Description;
        }
      }
      return rtn;
    },
    getFeeTypeKey(value) {
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.Description == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].FeeType;
        }
      }
      return rtn;
    },

    addExtraDraftFee() {
      if (
        this.extraFeesTabData.GlobalFeeType != null &&
        this.extraFeesTabData.FeeAmount > 0 &&
        this.extraFeesTabData.FeeDate != ""
      ) {
        let item = deepCopy(this.extraFeesTabData);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.extraPaymentData.ApplicableFees.push(item);
        this.extraFeesTabData.GlobalFeeType = null;
        this.extraFeesTabData.FeeAmount = 0;
        this.extraFeesTabData.FeeDate = "";
        this.calculateCashFlowForExtraPayment();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required",
          "warning"
        );
      }
    },
    addSkipPaymentFees() {
      if (
        this.skipyPaymentFees.GlobalFeeType != null &&
        this.skipyPaymentFees.FeeAmount > 0 &&
        this.skipyPaymentFees.FeeDate != ""
      ) {
        let item = deepCopy(this.skipyPaymentFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.skipPaymentData.ApplicableFees.push(item);

        this.skipyPaymentFees.GlobalFeeType = null;
        this.skipyPaymentFees.FeeAmount = 0;
        this.skipyPaymentFees.FeeDate = "";
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required",
          "warning"
        );
      }
    },
    deleteSkipPaymentFees(indx) {
      if (indx > -1) {
        this.skipPaymentData.ApplicableFees.splice(indx, 1);
      }
    },

    deleteExtraPaymentData(indx) {
      if (indx > -1) {
        this.extraPaymentData.ApplicableFees.splice(indx, 1);
        this.calculateCashFlowForExtraPayment();
      }
    },

    keyPress(event, item) {
      if (event.keyCode == 13) {
        this.handleEdit(item);
      }
    },
    handleEdit(row) {
      for (let item of this.drafts) {
        if (item !== row) {
          item.isEditing = false;
        }
      }

      row.isEditing = !row.isEditing;
    },

    handleEditCharges(row) {
      for (let item of this.chargeList) {
        if (item !== row) {
          item.isEditing = false;
        }
      }
      row.isEditing = !row.isEditing;
    },
    openChargeChangesPopup() {
      for (let item of this.chargeList) {
        item.Amount = parseFloat(item.Amount);
      }

      if (
        JSON.stringify(this.chargesChangeOldData) ==
        JSON.stringify(this.chargeList)
      ) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }

      this.chargeChanges = [];
      for (let i = 0; i < this.chargeList.length; i++) {
        if (
          new Date(this.chargeList[i].EffectiveDate).toString("M/d/yyyy") !=
            new Date(this.chargesChangeOldData[i].EffectiveDate).toString(
              "M/d/yyyy"
            ) ||
          this.chargeList[i].Amount != this.chargesChangeOldData[i].Amount
        ) {
          this.chargeChanges.push({
            ChargeId: this.chargeList[i].ChargeId,
            OriginalDate: new Date(
              this.chargesChangeOldData[i].EffectiveDate
            ).toString("M/d/yyyy"),
            ChargeDate: new Date(this.chargeList[i].EffectiveDate).toString(
              "M/d/yyyy"
            ),
            ChargeAmount: this.chargeList[i].Amount,
          });
        }
      }

      this.isChargeChangesPopupOpen = true;
    },
    closeChargeChangesPopup() {
      this.isChargeChangesPopupOpen = false;
      this.cashFlowList= deepCopy(this.cashFlowListOld);
    },
    openDraftChangePopup() {
      for (let item of this.drafts) {
        item.Amount = parseFloat(item.Amount);
      }

      if (
        JSON.stringify(this.draftChangeOldData) == JSON.stringify(this.drafts)
      ) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }

      this.draftChanges = [];
      for (let i = 0; i < this.drafts.length; i++) {
        if (
          new Date(this.drafts[i].EffectiveDate).toString("M/d/yyyy") !=
            new Date(this.draftChangeOldData[i].EffectiveDate).toString(
              "M/d/yyyy"
            ) ||
          this.drafts[i].Amount != this.draftChangeOldData[i].Amount
        ) {
          this.draftChanges.push({
            OriginalDate: new Date(
              this.draftChangeOldData[i].EffectiveDate
            ).toString("M/d/yyyy"),
            NewDate: new Date(this.drafts[i].EffectiveDate).toString(
              "M/d/yyyy"
            ),
            GlobalDebitId: this.drafts[i].DebitId,
            Amount: this.drafts[i].Amount,
          });
        }
      }

      this.isDraftChangesPopUp = true;
    },
    closeDraftChangesPopUp() {
      this.isDraftChangesPopUp = false;
      this.cashFlowList = deepCopy(this.cashFlowListOld);
    },

    submitDraftChanges(refreshGrid) {
      this.isDraftChangesPopUp = false;
      if (refreshGrid) {
        this.bindDraftGrid(true);
        this.bindCashFlowData();
      } else {
        this.drafts = deepCopy(this.draftChangeOldData);
      }
    },
    submitFeeChanges(refreshGrid) {
      this.isFeeChangesPopUp = false;
      if (refreshGrid) {
        this.bindFeesGrid(true);
        this.bindCashFlowData();
      } else {
        this.feeList = deepCopy(this.feeChangeOldData);
      }
    },
    submitChargeChanges(refreshGrid) {
      this.isChargeChangesPopupOpen = false;
      if (refreshGrid) {
        this.bindChargesGrid(true);
        this.bindCashFlowData();
      } else {
        this.chargeList = deepCopy(this.chargesChangeOldData);
      }
    },
    handleTabChange(tabName) {

      if (tabName == "extraPayment") {
        this.calculateCashFlowForExtraPayment();
      } else if (tabName == "skipPayment") {
        this.calculateCashFlowForSkipPayment(this.skipPaymentData.PaymentCount,this.skipPaymentData.DraftCount);
      }

      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name == tabName ? true : false;
        return item;
      });
      this.tabs = [...temp];

    
    },
    async handleRightTabChange(tabId, tabName, refresh) {
      let temp = [...this.rightTabs];
      temp = temp.map((item) => {
        item.active = item.name == tabName ? true : false;
        return item;
      });
      this.rightTabs = [...temp];
      if (tabId == 1) {
        await this.bindDraftGrid(refresh);
      } else if (tabId == 2) {
        await this.bindFeesGrid(refresh);
      } else if (tabId == 3) {
        await this.bindPaymentGrid(refresh);
      } else if (tabId == 4) {
        await this.bindChargesGrid(refresh);
      }
    },
    openExtraDrafts() {
      this.extraDraftsTab = !this.extraDraftsTab;
    },
    openSkipPaymentApplicableFees() {
      this.isOpenSkipPaymentApplicableFees =
        !this.isOpenSkipPaymentApplicableFees;
    },
    async additionalCommentRequest() {
      if (this.additionalCommentData.Notes.trim().length == 0) {
        this.$swal("Warning!", "Comment field is mandatory", "warning");
        return;
      }

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to add this comment request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoadingApproveButton = true;
          if (dialog.value) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.CREATE_ADDITIONAL_NOTE,
              {
                CustomerId: this.customerId,
                IsEmergency: this.additionalCommentData.IsEmergency,
                Notes: this.additionalCommentData.Notes,
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Additional comment requested.";
              this.$swal("Success!", successMessage, "success");

              this.additionalCommentData.IsEmergency = false;
              this.additionalCommentData.Notes = "";
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoadingApproveButton = false;
        });
    },
    async extraPaymentRequest() {
      if (
        this.extraPaymentData.Notes.trim().length == 0 ||
        this.extraPaymentData.DraftAmount == 0 ||
        this.extraPaymentData.DraftTypeId == null ||
        this.extraPaymentData.DraftDate == ""
      ) {
        this.$swal(
          "Warning!",
          "Draft Type, Draft Amount, Draft Date, Notes fields required.",
          "warning"
        );
        return;
      }

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoadingApproveButton = true;
          if (dialog.value) {
            let err, result;

            [err, result] = await this.$store.dispatch(
              types.CREATE_EXTRA_PAYMENT,
              {
                CustomerId: this.customerId,
                IsEmergency: this.extraPaymentData.IsEmergency,
                Notes: this.extraPaymentData.Notes,
                DraftAmount: parseFloat(this.extraPaymentData.DraftAmount),
                DraftTypeId: this.extraPaymentData.DraftTypeId,
                DraftDate: new Date(this.extraPaymentData.DraftDate).toString(
                  "M/d/yyyy"
                ),
                ApplicableFees: this.extraPaymentData.ApplicableFees,
                IsNegativeCashFlow:this.isNegativeCashFlowForExtraDraft
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Extra payment requested.";
              this.$swal("Success!", successMessage, "success");
              this.extraPaymentData.IsEmergency = false;
              this.extraPaymentData.Notes = "";
              this.extraPaymentData.DraftAmount = 0;
              this.extraPaymentData.DraftTypeId = null;
              this.extraPaymentData.DraftDate = "";
              this.extraPaymentData.ApplicableFees = [];
              this.extraDraftsTab = false;
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoadingApproveButton = false;
        });
    },
    async skipPaymentRequest() {

        let paymentCount = parseInt(this.skipPaymentData.PaymentCount);
        if(paymentCount == 0)
        {
           this.$swal("Warning","Please select a payment count","warning");
           return;
        }


      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoadingApproveButton = true;
          if (dialog.value) {
            let err, result;

            [err, result] = await this.$store.dispatch(
              types.CREATE_SKIP_PAYMENT,
              {
                CustomerId: this.customerId,
                IsEmergency: this.skipPaymentData.IsEmergency,
                Notes: this.skipPaymentData.Notes,
                DividePaymentCount: this.skipPaymentData.IsDividePayments
                  ? parseInt(this.skipPaymentData.DraftCount)
                  : 0,
                SkipPaymentCount: paymentCount,
                ApplicableFees: this.skipPaymentData.ApplicableFees,
                 IsNegativeCashFlow:this.isNegativeCashFlow
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Skip payment requested.";
              this.$swal("Success!", successMessage, "success");
              this.skipPaymentData.IsOnePayment = true;
              this.skipPaymentData.DraftCount = 0;
              this.skipPaymentData.PaymentCount = "1";
              this.skipPaymentData.IsDividePayments = false;
              this.skipPaymentData.Notes = "";
              this.skipPaymentData.IsEmergency = false;
              this.skipPaymentData.ApplicableFees = [];
              this.isOpenSkipPaymentApplicableFees = false;
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoadingApproveButton = false;
        });
    },
  },
};
</script>

<style scoped>
.stickyColumns {
  background-color: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
}

@media (min-width: 768px) {
  .space {
    flex: 0 0 8.33333%;
    max-width: 2.33333%;
  }
}

.tab-item .active {
  background-color: #3bafda;
  color: #ffffff !important;
}
.blueBoxControl {
  width: 70%;
  border-radius: 6px;
  padding: 5px 4%;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.cashflowMoveableDraft {
  position: absolute;
  min-width: 480px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 5px 2px #b7b3b3;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 2000;
}
</style>
